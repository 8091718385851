import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type UserSendErrorMailUpdateReq = {
  id?: number;
  send_error_mail: boolean;
};

export type UserSendErrorMailUpdateRes = null;

const useUserSendErrorMailUpdateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, UserSendErrorMailUpdateReq>(
    async (req: UserSendErrorMailUpdateReq) => {
      const { id, ...rest } = req;
      return (
        await axiosInstance()
          .post<UserSendErrorMailUpdateRes>(
            `/user/change_send_error_mail/${id}`,
            rest
          )
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useUserSendErrorMailUpdateMutation;
