import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Wosh } from '@/utils/wosh';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshDetailReq = {
  expand: string;
};

export type WoshDetailRes = Wosh;

const useWoshDetailQuery = (id: number, req?: WoshDetailReq) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['woshDetail', req],
    async () => {
      return (
        await axiosInstance()
          .get<WoshDetailRes>(`/wosh/${id}`, {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    {}
  );
};

export default useWoshDetailQuery;
