import { GroupUser } from '@/utils/group_user';
import { Company } from '@/utils/company';

export type User = {
  id: number;
  email: string;
  name: string;
  group_users: GroupUser[];
  company_id: number;
  company: Company;
  company_role: UserCompanyRole;
  admin_role: UserAdminRole;
  phone_number: string;
  send_error_mail: boolean;
  globalAbilities: {
    can_admin_user_show: boolean;
    can_admin_user_create: boolean;
    can_company_show: boolean;
    can_company_create: boolean;
    can_wosh_show: boolean;
    can_wosh_create: boolean;
    can_wosh_export: boolean;
    can_wosh_import: boolean;
    can_user_show: boolean;
    can_user_create: boolean;
    can_group_create: boolean;
  };
  abilities: {
    can_admin_user_update: boolean;
    can_user_update: boolean;
    can_user_delete: boolean;
  };
};

export const UserAdminRoles = {
  manager: 1,
  operator: 2,
  call_center: 3,
  factory: 4,
} as const;

export type UserAdminRole = typeof UserAdminRoles[keyof typeof UserAdminRoles];

export const UserCompanyRoles = {
  manager: 1,
  group_manager: 2,
  general: 3,
} as const;

export type UserCompanyRole =
  typeof UserCompanyRoles[keyof typeof UserCompanyRoles];

export function userIsAdmin(user: User | null | undefined) {
  if (!user) {
    return false;
  }

  return (
    !user.company_id && Object.values(UserAdminRoles).includes(user.admin_role)
  );
}

export function userIsCompany(user: User) {
  return (
    !!user.company_id &&
    Object.values(UserCompanyRoles).includes(user.company_role)
  );
}

export function userIsCompanyManager(user: User | null | undefined) {
  return user?.company_role === UserCompanyRoles.manager;
}

export function userIsCompanyGeneral(user: User) {
  return user.company_role === UserCompanyRoles.general;
}
