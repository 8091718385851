import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import ContentHeader from '@/components/common/ContentHeader';
import ContentWrapper from '@/components/common/ContentWrapper';
import ContentBlock from '@/components/common/ContentBlock';
import useCompanyListQuery from '@/hooks/useCompanyListQuery';
import useGroupListQuery, { GroupListReq } from '@/hooks/useGroupListQuery';
import useAuth from '@/hooks/useAuth';
import { userIsAdmin } from '@/utils/user';
import GroupAddModal from '@/pages/GroupList/GroupAddModal';
import { Company } from '@/utils/company';
import { Wosh } from '@/utils/wosh';
import GroupEditModal from '@/pages/GroupList/GroupEditModal';
import { Group } from '@/utils/group';
import useGroupDeleteMutation from '@/hooks/useGroupDeleteMutation';
import { formError } from '@/utils/form';

const productListContentStyle = css`
  padding: 0;
  margin-top: 50px;
`;

const headerRightStyle = css`
  padding: 0 44px;
`;

export type GroupListFormValues = {
  search: string;
  id__exact?: number;
  company_id__exact?: number;
  ordering: string;
  page: number;
  page_size: number;
};

const GroupList: React.FC = () => {
  const auth = useAuth();

  // groupList
  const [groupListForm] = useForm<GroupListFormValues>();
  const [groupListReq, setGroupListReq] = useState<GroupListReq>({
    ordering: '-created_at',
    page: 1,
    page_size: 100,
    expand: 'company,woshes',
  });
  const groupListQuery = useGroupListQuery(groupListReq);
  const groups = groupListQuery.data?.results;
  const handleGroupList = async () => {
    const values = groupListForm.getFieldsValue(true);
    await setGroupListReq({
      ...groupListReq,
      page: values.page,
      page_size: values.page_size,
      search: values.search,
      id__exact: values.id__exact,
      company_id__exact: values.company_id__exact,
      ordering: values.ordering,
    });
  };

  // companyList
  let companies: Company[] = [];
  if (userIsAdmin(auth.user)) {
    const companyListQuery = useCompanyListQuery({
      ordering: 'id',
    });
    companies = companyListQuery.data?.results || [];
  }
  // groupAddModal
  const [groupAddModalVisible, setGroupAddModalVisible] =
    useState<boolean>(false);

  // groupEditModal
  const [groupEditModalGroup, setGroupEditModalGroup] = useState<Group | null>(
    null
  );

  // groupDelete
  const groupDeleteMutation = useGroupDeleteMutation();
  const handleGroupDelete = (id: number) => {
    groupDeleteMutation.mutate(id, {
      onSuccess: async () => {
        notification.success({
          message: '削除しました。',
        });
        await groupListQuery.refetch();
      },
      onError: (e: AxiosError) => {
        if (e.response?.data.error) {
          notification.error({
            message: e.response?.data.error,
          });
        } else {
          formError(e);
        }
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>グループ一覧</title>
      </Helmet>
      <ContentWrapper style={productListContentStyle}>
        <ContentBlock>
          <ContentHeader title="グループ一覧">
            <div className={headerRightStyle}>
              {auth.user?.globalAbilities?.can_group_create && (
                <Button
                  type="primary"
                  shape="round"
                  ghost
                  onClick={() => {
                    setGroupAddModalVisible(true);
                  }}
                >
                  グループ追加
                </Button>
              )}
            </div>
          </ContentHeader>
          <Collapse>
            <Collapse.Panel key="1" header="検索">
              <Form<GroupListFormValues>
                form={groupListForm}
                layout="vertical"
                onFinish={async () => {
                  groupListForm.setFieldsValue({
                    page: 1,
                  });
                  await handleGroupList();
                }}
              >
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="検索" name="search">
                      <Input placeholder="名前、連絡先" />
                    </Form.Item>
                  </Col>
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="ID" name="id__exact">
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="組織" name="company_id__exact">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          allowClear
                        >
                          {companies?.map((company) => (
                            <Select.Option key={company.id} value={company.id}>
                              {company.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                      label="並び順"
                      name="ordering"
                      initialValue="-created_at"
                    >
                      <Select showSearch optionFilterProp="children">
                        <Select.Option value="created_at">
                          作成日時 : 昇順
                        </Select.Option>
                        <Select.Option value="-created_at">
                          作成日時 : 降順
                        </Select.Option>
                        <Select.Option value="name">名前 : 昇順</Select.Option>
                        <Select.Option value="-name">名前 : 降順</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={groupListQuery.isLoading}
                  >
                    検索
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      groupListForm.resetFields();
                      groupListForm.submit();
                    }}
                  >
                    リセット
                  </Button>
                </Space>
              </Form>
            </Collapse.Panel>
          </Collapse>
          {groupListQuery.isError && (
            <Result status="warning" title="データの読み込みに失敗しました" />
          )}
          {groupListQuery.isSuccess && (
            <Table
              rowKey="id"
              dataSource={groups}
              scroll={{ x: 900 }}
              loading={groupListQuery.isLoading}
              pagination={{
                position: ['topRight', 'bottomRight'],
                current: groupListReq.page,
                total: groupListQuery.data?.count,
                pageSize: groupListReq.page_size,
                showTotal: (total: number, range: number[]) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: async (page, page_size) => {
                  groupListForm.setFieldsValue({
                    page: page_size === groupListReq.page_size ? page : 1,
                    page_size,
                  });
                  await handleGroupList();
                },
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '500'],
              }}
            >
              {userIsAdmin(auth.user) && (
                <Table.Column title="ID" dataIndex="id" key="id" />
              )}
              {userIsAdmin(auth.user) && (
                <Table.Column
                  title="組織"
                  key="company"
                  dataIndex="company"
                  render={(company: Company) => company?.name}
                />
              )}
              <Table.Column title="名称" dataIndex="name" key="name" />
              <Table.Column
                title="連絡先"
                dataIndex="contract"
                key="contract"
              />
              <Table.Column
                title="wosh数"
                dataIndex="woshes"
                key="woshes"
                render={(woshes: Wosh[]) => {
                  return woshes?.length;
                }}
              />
              <Table.Column
                title=""
                key="edit"
                render={(_, group: Group) => (
                  <Space>
                    {group?.abilities?.can_group_update && (
                      <Button
                        type="primary"
                        shape="round"
                        ghost
                        onClick={() => {
                          setGroupEditModalGroup(group);
                        }}
                      >
                        EDIT
                      </Button>
                    )}
                    {group?.abilities?.can_group_delete && (
                      <Popconfirm
                        title={`${group.name}を削除しますか？`}
                        okText="削除する"
                        cancelText="キャンセル"
                        onConfirm={() => handleGroupDelete(group.id)}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                    <GroupEditModal
                      group={group}
                      visible={groupEditModalGroup === group}
                      onFinish={async () => {
                        setGroupEditModalGroup(null);
                        await groupListQuery.refetch();
                      }}
                      onCancel={() => setGroupEditModalGroup(null)}
                    />
                  </Space>
                )}
              />
            </Table>
          )}
        </ContentBlock>
      </ContentWrapper>
      <GroupAddModal
        companies={companies}
        visible={groupAddModalVisible}
        onFinish={async () => {
          await groupListQuery.refetch();
          setGroupAddModalVisible(false);
        }}
        onCancel={() => setGroupAddModalVisible(false)}
      />
    </>
  );
};

export default GroupList;
