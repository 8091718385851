import React from 'react';
import { css } from '@emotion/css';
import ContentBlock from '@/components/common/ContentBlock';
import ContentHeader from '@/components/common/ContentHeader';
import Graph from '@/components/wosh/WoshUsageStats/Graph';
import { Wosh } from '@/utils/wosh';

type WoshUsageStatsProps = {
  // eslint-disable-next-line react/require-default-props
  wosh?: Wosh;
};

type WoshUsageStatsComponent = (props: WoshUsageStatsProps) => JSX.Element;

const woshDetailContentStyle = {
  padding: 0,
  marginTop: '50px',
};

const woshDetailContentInnerStyle = css`
  padding: 40px;
`;

const WoshUsageStats: WoshUsageStatsComponent = ({
  wosh,
}: WoshUsageStatsProps) => {
  return (
    <ContentBlock id="status" style={woshDetailContentStyle}>
      <ContentHeader title="利用状況" />
      <div className={woshDetailContentInnerStyle}>
        <Graph wosh={wosh} />
      </div>
    </ContentBlock>
  );
};

export default WoshUsageStats;
