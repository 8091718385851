import { Button, Form, Input, Modal, notification, Select } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useUserCreateMutation, {
  UserCreateReq,
} from '@/hooks/useUserCreateMutation';
import { Company } from '@/utils/company';
import {
  formItemValidateStatus,
  formItemValidateMessage,
  formError,
} from '@/utils/form';
import useAuth from '@/hooks/useAuth';
import { userIsAdmin, userIsCompanyManager } from '@/utils/user';

type UserAddModalProps = {
  visible: boolean;
  companies: Company[] | undefined;
  onFinish: () => void;
  onCancel: () => void;
};

const UserAddModal: React.FC<UserAddModalProps> = ({
  visible,
  companies,
  onFinish,
  onCancel,
}) => {
  const auth = useAuth();
  const [userCreateForm] = useForm();
  const userCreateMutation = useUserCreateMutation();
  const handleUserCreate = (values: any) => {
    const req: UserCreateReq = {
      company: auth.user?.admin_role
        ? values.company_id
        : auth.user?.company?.id,
      email: values.email,
      name: values.name,
      password: values.password,
      phone_number: values.phone_number || '',
      company_role: values.company_role,
    };
    userCreateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '作成しました',
        });
        onFinish();
        userCreateForm.resetFields();
      },
      onError: formError,
    });
  };

  return (
    <Modal
      footer={null}
      title="ユーザー追加"
      visible={visible}
      onCancel={onCancel}
    >
      <Form onFinish={handleUserCreate} form={userCreateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="名前"
            name="name"
            validateStatus={formItemValidateStatus(
              userCreateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(userCreateMutation.error, 'name')}
          >
            <Input size="large" placeholder="名前を入力してください" />
          </Form.Item>
          <Form.Item
            label="Eメール"
            name="email"
            validateStatus={formItemValidateStatus(
              userCreateMutation.error,
              'email'
            )}
            help={formItemValidateMessage(userCreateMutation.error, 'email')}
          >
            <Input size="large" placeholder="Eメールを入力してください" />
          </Form.Item>
          <Form.Item
            label="パスワード"
            name="password"
            validateStatus={formItemValidateStatus(
              userCreateMutation.error,
              'password'
            )}
            help={formItemValidateMessage(userCreateMutation.error, 'password')}
          >
            <Input size="large" placeholder="パスワードを入力してください" />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name="phone_number"
            validateStatus={formItemValidateStatus(
              userCreateMutation.error,
              'phone_number'
            )}
            help={formItemValidateMessage(
              userCreateMutation.error,
              'phone_number'
            )}
          >
            <Input size="large" placeholder="電話番号を入力してください" />
          </Form.Item>
          <Form.Item
            label="権限"
            name="company_role"
            validateStatus={formItemValidateStatus(
              userCreateMutation.error,
              'company_role'
            )}
            help={formItemValidateMessage(
              userCreateMutation.error,
              'company_role'
            )}
          >
            <Select showSearch optionFilterProp="children">
              {/* Adminとカンパニーマネージャーは許可 */}
              <Select.Option
                value={1}
                disabled={
                  !userIsAdmin(auth.user) && !userIsCompanyManager(auth.user)
                }
              >
                オーナー
              </Select.Option>
              <Select.Option value={2}>グループ管理者</Select.Option>
              <Select.Option value={3}>一般</Select.Option>
            </Select>
          </Form.Item>
          {userIsAdmin(auth.user) && (
            <Form.Item
              label="組織"
              name="company_id"
              validateStatus={formItemValidateStatus(
                userCreateMutation.error,
                'company'
              )}
              help={formItemValidateMessage(
                userCreateMutation.error,
                'company'
              )}
            >
              <Select showSearch optionFilterProp="children">
                {companies?.map((tmp_company) => {
                  return (
                    <Select.Option key={tmp_company.id} value={tmp_company.id}>
                      {tmp_company.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              追加する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UserAddModal;
