import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

type CompanyDeletePathParam = {
  id: number;
};

type CompanyDeleteRespBody = null;

export type CompanyDeleteMutationVariables = {
  companyDeletePathParam: CompanyDeletePathParam;
};

const deleteCompany = async (
  companyDeletePathParam: CompanyDeletePathParam
) => {
  const axiosResponse = await axiosInstance().post<CompanyDeleteRespBody>(
    `/company/delete/${companyDeletePathParam.id}`
  );

  return axiosResponse.data;
};

const useCompanyDeleteMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();

  return useMutation<
    CompanyDeleteRespBody,
    AxiosError<ErrorRes>,
    CompanyDeleteMutationVariables
  >(async (mutationVariables) => {
    const { companyDeletePathParam } = mutationVariables;

    try {
      return await deleteCompany(companyDeletePathParam);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosErrorHandler(error);
        throw error;
      }

      throw error;
    }
  });
};

export default useCompanyDeleteMutation;
