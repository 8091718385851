import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Company } from '@/utils/company';
import { ListReq } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type CompanyListReq = ListReq & {
  id__exact?: number;
  name__contains?: string;
  ordering: string;
  expand?: string;
};

export type CompanyListRes = {
  count: number;
  results: Company[];
};

const useCompanyListQuery = (req: CompanyListReq, options?: any) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['companyList', req],
    async () => {
      return (
        await axiosInstance()
          .get<CompanyListRes>('/company', {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    options
  );
};

export default useCompanyListQuery;
