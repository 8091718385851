export const WoshEventType = {
  16: '内部洗浄',
  32: '塩素補充',
} as const;

export type WoshEventTypeKeyType = keyof typeof WoshEventType;

export type WoshEvent = {
  id: number;
  event_type: WoshEventTypeKeyType;
  main_software_version: string;
  ui_software_version: string;
  communication_software_version: string;
  boots: number;
  timestamp: string;
  created_at: string;
  updated_at: string;
};
