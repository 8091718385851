import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Wosh } from '@/utils/wosh';
import { ListReq, ListRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshListReq = ListReq & {
  id__exact?: number;
  serial_number__exact?: string;
  dangling_serial_number?: boolean;
  device_id__exact?: string;
  search?: string;
  company_id__exact?: number;
  group_id__exact?: number;
  status_level__exact?: number;
  error_code__exact?: string;
  ordering?: string;
  expand?: string;
  page_size?: number;
};

export type WoshListRes = ListRes & {
  results: Wosh[];
};

const useWoshListQuery = (req: WoshListReq, options?: any) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['woshList', req],
    async () => {
      return (
        await axiosInstance()
          .get<WoshListRes>('/wosh', {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    options
  );
};

export default useWoshListQuery;
