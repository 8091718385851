import React from 'react';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Logo } from '@/assets/img/logo_w.svg';

const wrapperStyle = css`
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: transparent
    linear-gradient(171deg, #37c8d6 0%, #26b4de 51%, #39fef4 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const blockStyle = css`
  max-width: 500px;
  width: 100%;
  margin: auto;
  text-align: center;
`;

const logoStyle = css`
  display: block;
  max-width: 312px;
  margin: 0 auto 70px;
  max-width: 100%;
`;

const PasswordResetComplete: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>メール送信完了</title>
      </Helmet>
      <div className={wrapperStyle}>
        <div className={blockStyle}>
          <Logo className={logoStyle} />
          <p className="text-xl font-bold text-white mb-10">送信完了しました</p>
          <p className="text-sm text-white mb-8">
            ご入力いただいたメールアドレスにパスワードのご案内を送信しました。
            <br />
            メールボックスをご確認ください。
          </p>
          <a
            className="rounded-full py-3 px-6 text-white border-white border-2 border-solid font-bold"
            href="/passwordReset"
          >
            もう一度送る
          </a>
        </div>
      </div>
    </>
  );
};

export default PasswordResetComplete;
