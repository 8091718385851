import React, { ReactElement } from 'react';
import './App.less';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import WoshList from '@/pages/WoshList';
import WoshDetail from '@/pages/WoshDetail';
import { AuthProvider } from '@/contexts/auth';
import Login from '@/pages/Login';
import AuthGuard from '@/components/auth/AuthGuard';
import UserList from '@/pages/UserList';
import UserDetail from '@/pages/UserDetail';
import CompanyList from '@/pages/CompanyList';
import CompanyDetail from '@/pages/CompanyDetail';
import GroupList from '@/pages/GroupList';
import GroupDetail from '@/pages/GroupDetail';
import AdminList from '@/pages/AdminList';
import AdminDetail from '@/pages/AdminDetail';
import PasswordReset from '@/pages/PasswordReset';
import PasswordResetComplete from '@/pages/PasswordReset/complete';
import PasswordResetConfirm from '@/pages/PasswordReset/confirm';
import AppLayout from './layouts/AppLayout';
import Home from './pages/Home';
import { TrackingView } from './components/tracking';
import SEO from './components/seo';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

if (process.env.TARGET === 'local') {
  Sentry.init({
    // clientはdomainで制限するので、直commit
    dsn: 'https://1714892c1faf42f89c42e3906af7757a@o1086976.ingest.sentry.io/6099636',
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    environment: process.env.REACT_APP_TARGET,
  });
}

const App: React.FC = (): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <SEO />
      <div className="App">
        <Router>
          <TrackingView>
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/passwordReset" element={<PasswordReset />} />
                <Route
                  path="/passwordReset/complete"
                  element={<PasswordResetComplete />}
                />
                <Route
                  path="/passwordReset/confirm/:uidb64/:token"
                  element={<PasswordResetConfirm />}
                />
                <Route path="/" element={<AppLayout />}>
                  <Route element={<AuthGuard />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/wosh" element={<WoshList />} />
                    <Route path="/wosh/:woshId" element={<WoshDetail />} />
                    <Route path="/user" element={<UserList />} />
                    <Route path="/user/:userId" element={<UserDetail />} />
                    <Route path="/admin" element={<AdminList />} />
                    <Route path="/admin/:userId" element={<AdminDetail />} />
                    <Route path="/company" element={<CompanyList />} />
                    <Route
                      path="/company/:companyId"
                      element={<CompanyDetail />}
                    />
                    <Route path="/group" element={<GroupList />} />
                    <Route path="/group/:groupId" element={<GroupDetail />} />
                  </Route>
                </Route>
              </Routes>
            </AuthProvider>
          </TrackingView>
        </Router>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
