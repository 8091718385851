import React from 'react';
import { Layout } from 'antd';
import { css } from '@emotion/css';

import { ReactComponent as FooterLogo } from '@/assets/img/footer_logo.svg';
import { ReactComponent as CompanyLogo } from '@/assets/img/company_logo.svg';

const footerStyle = css`
  padding: 0;
`;

const footerInnerStyle = css`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const logoStyle = css`
  @media (max-width: 991px) {
    order: 1;
  }
`;

const copyStyle = css`
  color: #c3c3c3;

  @media (max-width: 991px) {
    order: 3;
  }
`;

const brandWrapperStyle = css`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    order: 2;
  }
`;

const brandSubscriptStyle = css`
  margin-right: 10px;
  flex-shrink: 0;
  color: #c3c3c3;
`;

const brandLogoStyle = css`
  flex-shrink: 0;
`;

const Footer: React.FC = () => (
  <Layout.Footer className={footerStyle}>
    <div className={footerInnerStyle}>
      <FooterLogo className={logoStyle} />
      <span className={copyStyle}>
        Copyright © WOTA CORP. All rights reserved.
      </span>
      <span className={brandWrapperStyle}>
        <span className={brandSubscriptStyle}>Powered by</span>
        <CompanyLogo className={brandLogoStyle} />
      </span>
    </div>
  </Layout.Footer>
);

export default Footer;
