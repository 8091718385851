import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import ContentHeader from '@/components/common/ContentHeader';
import ContentWrapper from '@/components/common/ContentWrapper';
import ContentBlock from '@/components/common/ContentBlock';
import WoshActiveTag from '@/components/wosh/WoshActiveTag';
import WoshStatusTag from '@/components/wosh/WoshStatusTag';
import useWoshListQuery, { WoshListReq } from '@/hooks/useWoshListQuery';
import { Wosh } from '@/utils/wosh';
import useAuth from '@/hooks/useAuth';
import useCompanyListQuery from '@/hooks/useCompanyListQuery';
import useGroupListQuery, { GroupListReq } from '@/hooks/useGroupListQuery';
import { userIsAdmin } from '@/utils/user';
import useWoshErrorListQuery from '@/hooks/useWoshErrorListQuery';
import WoshAddModal from '@/pages/WoshList/WoshAddModal';
import { Company } from '@/utils/company';
import useWoshDeleteMutation, {
  WoshDeleteMutationVariables,
} from '@/hooks/useWoshDeleteMutation';
import useWoshCsvExportMutation, {
  WoshCsvExportReq,
} from '@/hooks/useWoshCsvExportMutation';
import useWoshCsvImportMutation from '@/hooks/useWoshCsvImportMutation';

const woshListContentStyle = css`
  padding: 0;
  margin-top: 50px;
`;

const headerRightStyle = css``;

const headerItems = css`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  gap: 8px;
`;

export type WoshListFormValues = {
  search: string;
  id__exact: number;
  company_id__exact: number;
  group_id__exact: number;
  status_level__exact: number;
  error_code__exact: string;
  ordering: string;
  page: number;
  page_size: number;
};

const { Option } = Select;

const WoshList: React.FC = () => {
  const auth = useAuth();

  // groups
  const [groupListReq, setGroupListReq] = useState<GroupListReq>({
    ordering: 'id',
    // ページネーションがかからないように大きい数字を設定する
    page_size: 9999999,
  });
  const groupListQuery = useGroupListQuery(groupListReq);
  const groups = groupListQuery.data?.results;

  // companies
  let companies: Company[] | undefined;
  if (userIsAdmin(auth.user)) {
    const companyListQuery = useCompanyListQuery({
      ordering: 'id',
    });
    companies = companyListQuery.data?.results;
  }

  const woshErrorListQuery = useWoshErrorListQuery({
    ordering: 'code',
  });
  const wosh_errors = woshErrorListQuery.data?.results;

  const handleCompanyChange = async (value: number) => {
    if (value) {
      await setGroupListReq({
        ...groupListReq,
        company_id__exact: value,
      });
      await groupListQuery.refetch();
    }
  };

  // woshes
  const [woshListForm] = useForm<WoshListFormValues>();
  const [woshListReq, setWoshListReq] = useState<WoshListReq>({
    expand: 'group,company',
    ordering: '-created_at',
    page: 1,
    page_size: 100,
  });
  const woshListQuery = useWoshListQuery(woshListReq);
  const woshes = woshListQuery.data?.results;
  const handleWoshList = async () => {
    const values = woshListForm.getFieldsValue(true);
    await setWoshListReq({
      ...woshListReq,
      search: values.search,
      id__exact: values.id__exact,
      serial_number__exact: values.serial_number__exact,
      device_id__exact: values.device_id__exact,
      company_id__exact: values.company_id__exact,
      group_id__exact: values.group_id__exact,
      status_level__exact: values.status_level__exact,
      error_code__exact: values.error_code__exact,
      ordering: values.ordering,
      page: values.page,
      page_size: values.page_size,
    });
  };
  // woshAddModal
  const [woshAddModalVisible, setWoshAddModalVisible] =
    useState<boolean>(false);

  // Delete Wosh
  const woshDeleteMutation = useWoshDeleteMutation();
  const handleWoshDelete = (
    woshId: WoshDeleteMutationVariables['woshDeletePathParam']['id']
  ) => {
    const mutationVariables: WoshDeleteMutationVariables = {
      woshDeletePathParam: {
        id: woshId,
      },
    };
    woshDeleteMutation.mutate(mutationVariables, {
      onSuccess: async () => {
        notification.success({
          message: '削除しました。',
        });
        await woshListQuery.refetch();
      },
      onError: (e: AxiosError) => {
        if (!e.response) return;

        notification.error({
          message: e.response.data.error,
        });
      },
    });
  };
  const woshCsvExportMutation = useWoshCsvExportMutation();
  const handleWoshCsvExport = () => {
    const req: WoshCsvExportReq = {};
    woshCsvExportMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: 'CSVをダウンロードしました',
        });
      },
      onError: () =>
        notification.error({
          message: 'CSV出力失敗しました',
        }),
    });
  };

  const woshCsvImportMutation = useWoshCsvImportMutation();
  const handleWoshCsvImport = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (event) => {
      const target: HTMLInputElement = event.target as HTMLInputElement;
      if (!target.files || target.files.length === 0) return;
      const req = { file: target.files[0] };
      woshCsvImportMutation.mutate(req, {
        onSuccess: async () => {
          notification.success({
            message: 'CSVインポート成功しました',
          });
          await woshListQuery.refetch();
        },
        onError: () =>
          notification.error({
            message: 'インポート失敗しました',
          }),
      });
    };
    input.click();
  };

  return (
    <>
      <Helmet>
        <title>製品一覧</title>
      </Helmet>
      <ContentWrapper style={woshListContentStyle}>
        <ContentBlock>
          <ContentHeader title="製品一覧">
            <div className={headerItems}>
              <div className={headerRightStyle}>
                {auth.user?.globalAbilities?.can_wosh_create && (
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    onClick={() => {
                      setWoshAddModalVisible(true);
                    }}
                  >
                    製品追加
                  </Button>
                )}
              </div>
              <div className={headerRightStyle}>
                {auth.user?.globalAbilities?.can_wosh_export && (
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    onClick={handleWoshCsvExport}
                  >
                    CSV出力
                  </Button>
                )}
              </div>
              <div className={headerRightStyle}>
                {auth.user?.globalAbilities?.can_wosh_import && (
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    onClick={handleWoshCsvImport}
                  >
                    CSV入力
                  </Button>
                )}
              </div>
            </div>
          </ContentHeader>
          <Collapse>
            <Collapse.Panel key="1" header="検索">
              <Form<WoshListFormValues>
                form={woshListForm}
                layout="vertical"
                onFinish={async () => {
                  woshListForm.setFieldsValue({
                    page: 1,
                  });
                  await handleWoshList();
                }}
              >
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="検索" name="search">
                      <Input placeholder="場所、製造番号" />
                    </Form.Item>
                  </Col>
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="組織" name="company_id__exact">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          allowClear
                          onChange={handleCompanyChange}
                        >
                          {companies?.map((company) => (
                            <Select.Option key={company.id} value={company.id}>
                              {company.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} lg={8}>
                    <Form.Item label="グループ" name="group_id__exact">
                      <Select showSearch optionFilterProp="children" allowClear>
                        {groups?.map((group) => {
                          return (
                            <Select.Option key={group.id} value={group.id}>
                              {group.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="ID" name="id__exact">
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} lg={8}>
                    <Form.Item label="製造番号" name="serial_number__exact">
                      <Input />
                    </Form.Item>
                  </Col>
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="デバイスID" name="device_id__exact">
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} lg={8}>
                    <Form.Item label="ステータス" name="status_level__exact">
                      <Select showSearch optionFilterProp="children" allowClear>
                        <Option value={0}>正常運転中</Option>
                        <Option value={1}>お手入れ（準備）</Option>
                        <Option value={2}>お手入れ（至急）</Option>
                        <Option value={3}>エラー</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="エラーコード" name="error_code__exact">
                      <Select allowClear showSearch optionFilterProp="children">
                        {wosh_errors?.map((wosh_error) => {
                          return (
                            <Select.Option
                              key={wosh_error.id}
                              value={wosh_error.code}
                            >
                              {`${wosh_error.code} ${wosh_error.name}`}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                      label="並び順"
                      name="ordering"
                      initialValue="-created_at"
                    >
                      <Select showSearch optionFilterProp="children">
                        <Select.Option value="created_at">
                          作成日時 : 昇順
                        </Select.Option>
                        <Select.Option value="-created_at">
                          作成日時 : 降順
                        </Select.Option>
                        <Select.Option value="company_id,group_id">
                          組織・グループ : 昇順
                        </Select.Option>
                        <Select.Option value="-company_id,-group_id">
                          組織・グループ : 降順
                        </Select.Option>
                        <Select.Option value="place">
                          設置場所 : 昇順
                        </Select.Option>
                        <Select.Option value="-place">
                          設置場所 : 降順
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={woshListQuery.isLoading}
                  >
                    検索
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      woshListForm.resetFields();
                      woshListForm.submit();
                    }}
                  >
                    リセット
                  </Button>
                </Space>
              </Form>
            </Collapse.Panel>
          </Collapse>
          {woshListQuery.isError && (
            <Result status="warning" title="データの読み込みに失敗しました" />
          )}
          {woshListQuery.isSuccess && (
            <Table
              rowKey="id"
              dataSource={woshes}
              scroll={{ x: 900 }}
              loading={woshListQuery.isLoading}
              pagination={{
                position: ['topRight', 'bottomRight'],
                current: woshListReq.page,
                total: woshListQuery.data?.count,
                pageSize: woshListReq.page_size,
                showTotal: (total: number, range: number[]) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: async (page, page_size) => {
                  woshListForm.setFieldsValue({
                    page: page_size === woshListReq.page_size ? page : 1,
                    page_size,
                  });
                  await handleWoshList();
                },
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '500'],
              }}
            >
              <Table.Column
                width={40}
                title=""
                key="id"
                render={(_, wosh: Wosh) => (
                  <Button type="primary" shape="round" ghost>
                    <Link to={`/wosh/${wosh.id}`}>詳細</Link>
                  </Button>
                )}
              />
              {userIsAdmin(auth.user) && (
                <Table.Column
                  title="ID"
                  key="id"
                  render={(_, wosh: Wosh) => wosh.id}
                />
              )}
              {userIsAdmin(auth.user) && (
                <Table.Column
                  title="組織"
                  key="company"
                  render={(_, wosh: Wosh) => wosh.company?.name}
                />
              )}
              <Table.Column
                title="グループ"
                key="group"
                render={(_, wosh: Wosh) => wosh.group?.name}
              />
              <Table.Column
                title="製造番号"
                dataIndex="serial_number"
                key="serial_number"
              />
              {userIsAdmin(auth.user) && (
                <Table.Column
                  title="デバイスID"
                  dataIndex="device_id"
                  key="device_id"
                />
              )}
              <Table.Column title="設置場所" dataIndex="place" key="place" />
              <Table.Column
                title="通信状態"
                dataIndex="active"
                key="active"
                render={(_, wosh: Wosh) => <WoshActiveTag wosh={wosh} />}
              />
              <Table.Column
                title="状態"
                dataIndex="status"
                key="status"
                render={(_, wosh: Wosh) => <WoshStatusTag wosh={wosh} />}
              />
              <Table.Column
                key="delete"
                render={(_, wosh: Wosh) => (
                  <Space>
                    {wosh.abilities.can_wosh_delete && (
                      <Popconfirm
                        placement="topRight"
                        title={`${wosh.serial_number}を削除しますか？`}
                        disabled={!wosh.abilities.can_wosh_delete}
                        okText="削除する"
                        cancelText="キャンセル"
                        onConfirm={() => handleWoshDelete(wosh.id)}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                  </Space>
                )}
              />
            </Table>
          )}
        </ContentBlock>
      </ContentWrapper>
      {companies && groups && (
        <WoshAddModal
          companies={companies}
          groups={groups}
          visible={woshAddModalVisible}
          onFinish={async () => {
            await woshListQuery.refetch();
            setWoshAddModalVisible(false);
          }}
          onCancel={() => setWoshAddModalVisible(false)}
        />
      )}
    </>
  );
};

export default WoshList;
