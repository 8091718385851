import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { notification } from 'antd';
import { AuthContext } from '@/contexts/auth';

const AuthGuard: React.FC = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.login();
  }, []);

  useEffect(() => {
    if (authContext.user === null) {
      notification.info({
        message: 'ログインして下さい。',
      });
      navigate('/login', {
        replace: true,
      });
    }
  }, [authContext.user]);

  if (authContext.user) {
    return <Outlet />;
  }
  return null;
};

export default AuthGuard;
