import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshCsvImportReq = { file: File };

export type WoshCsvImportRes = Record<string, never>;

const useWoshCsvImportMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<WoshCsvImportRes, AxiosError<ErrorRes>, WoshCsvImportReq>(
    async (req: WoshCsvImportReq): Promise<WoshCsvImportRes> => {
      const formData = new FormData();
      formData.append('file', req.file);
      return (
        await axiosInstance()
          .post<WoshCsvImportRes>('/wosh/import', formData)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useWoshCsvImportMutation;
