/* eslint-disable react/require-default-props */
import React from 'react';
import { css } from '@emotion/css';

const contentBlockStyle = css`
  background-color: #fff;
  box-shadow: 0px 1px 4px #00000012;
  border-radius: 4px;
  overflow: hidden;
`;

type ContentBlockProps = {
  id?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

type ContentBlockComponent = (props: ContentBlockProps) => JSX.Element;

const ContentBlock: ContentBlockComponent = ({
  id,
  children,
  style,
}: ContentBlockProps) => (
  <div id={id ?? ''} className={contentBlockStyle} style={style ?? {}}>
    {children}
  </div>
);

export default ContentBlock;
