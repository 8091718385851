import React from 'react';
import { Tag } from 'antd';
import { User, UserCompanyRoles } from '@/utils/user';

type UserCompanyRoleProps = {
  user: User;
};
const UserCompanyRole: React.FC<UserCompanyRoleProps> = ({ user }) => {
  if (user.company_role === UserCompanyRoles.manager) {
    return <Tag>オーナー</Tag>;
  }
  if (user.company_role === UserCompanyRoles.group_manager) {
    return <Tag>グループ管理者</Tag>;
  }
  if (user.company_role === UserCompanyRoles.general) {
    return <Tag>一般</Tag>;
  }
  return <span>{user.company_role}</span>;
};

export default UserCompanyRole;
