import { AxiosError } from 'axios';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { notification } from 'antd';

export function formError(error: AxiosError) {
  if (error.response?.status === 400) {
    notification.error({
      message: 'エラーがあります。内容を確認して下さい。',
    });
    return;
  }
  if (
    error.response?.status &&
    error.response?.status >= 400 &&
    error.response?.status <= 499
  ) {
    notification.error({
      message: 'エラーが発生しました。',
    });
    return;
  }
  if (
    error.response?.status &&
    error.response?.status >= 500 &&
    error.response?.status <= 599
  ) {
    notification.error({
      message: 'サーバーエラーが発生しました。',
    });
  }
}

export function formItemValidateStatus(
  error: AxiosError | null,
  field: string
): ValidateStatus {
  if (!error) {
    return '';
  }
  if (error?.response?.data?.[field]?.[0]) {
    return 'error';
  }
  return '';
}

export function formItemValidateMessage(
  error: AxiosError | null,
  field: string
): string | null {
  if (!error) {
    return null;
  }
  if (error?.response?.data?.[field]?.[0]) {
    return error?.response?.data?.[field]?.[0];
  }
  return null;
}
