import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { User } from '@/utils/user';
import useAuthUserQuery from '@/hooks/useAuthUserQuery';

export type AuthValue = {
  user: User | null | undefined;
  login: () => void;
  logout: () => void;
};

export const AuthContext = React.createContext<AuthValue>({
  user: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
});

type AuthProviderProps = {
  children: React.ReactNode;
};
// const AuthConsumer = AuthContext.Consumer;
export const AuthProvider: FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<null | User | undefined>();
  const authUserQuery = useAuthUserQuery();
  useEffect(() => {
    if (authUserQuery.isSuccess) {
      setUser(authUserQuery.data);
    }
    if (authUserQuery.isError) {
      setUser(null);
    }
  }, [authUserQuery.data]);
  const login = async () => {
    const result = await authUserQuery.refetch();
    if (result.isSuccess) {
      setUser(result.data);
    } else {
      setUser(null);
    }
  };
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(null);
    navigate('/login');
  };
  const value = useMemo(() => ({ user, login, logout }), [user]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
