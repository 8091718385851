import { Button, Form, Modal, Select, notification, Input } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useUserUpdateMutation, {
  UserUpdateReq,
} from '@/hooks/useUserUpdateMutation';
import { User, userIsAdmin, userIsCompanyManager } from '@/utils/user';
import {
  formError,
  formItemValidateMessage,
  formItemValidateStatus,
} from '@/utils/form';
import useAuth from '@/hooks/useAuth';

type UserEditModalProps = {
  visible: boolean;
  user: User;
  onFinish: () => void;
  onCancel: () => void;
};

const UserEditModal: React.FC<UserEditModalProps> = ({
  visible,
  user,
  onFinish,
  onCancel,
}) => {
  const auth = useAuth();
  const initialGroupIds = user?.group_users?.map(
    (group_user) => group_user?.group_id
  );

  const [userUpdateForm] = useForm();
  const userUpdateMutation = useUserUpdateMutation();
  const handleUserUpdate = (values: any) => {
    const req: UserUpdateReq = {
      id: user.id,
      user_ids: [user.id],
      email: values?.email,
      name: values?.name,
      phone_number: values?.phone_number || '',
      group_ids:
        values?.group_ids ||
        user.group_users.map((group_user) => group_user.group_id),
      company_role: values?.company_role || 3,
    };
    userUpdateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        onFinish();
        userUpdateForm.resetFields();
      },
      onError: formError,
    });
  };

  return (
    <Modal
      footer={null}
      onCancel={onCancel}
      visible={visible}
      title="ユーザー更新"
    >
      <Form onFinish={handleUserUpdate} form={userUpdateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="名前"
            name="name"
            initialValue={user?.name}
            validateStatus={formItemValidateStatus(
              userUpdateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(userUpdateMutation.error, 'name')}
          >
            <Input size="large" placeholder="名前を入力してください" />
          </Form.Item>
          <Form.Item
            label="Eメール"
            name="email"
            initialValue={user?.email}
            validateStatus={formItemValidateStatus(
              userUpdateMutation.error,
              'email'
            )}
            help={formItemValidateMessage(userUpdateMutation.error, 'email')}
          >
            <Input size="large" placeholder="Eメールを入力してください" />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name="phone_number"
            initialValue={user?.phone_number}
            validateStatus={formItemValidateStatus(
              userUpdateMutation.error,
              'phone_number'
            )}
            help={formItemValidateMessage(
              userUpdateMutation.error,
              'phone_number'
            )}
          >
            <Input size="large" placeholder="電話番号を入力してください" />
          </Form.Item>
          {(auth.user?.company_role === 1 ||
            auth.user?.company_role === 2 ||
            userIsAdmin(auth.user)) && (
            <>
              <Form.Item
                label="グループ"
                name="group_ids"
                initialValue={initialGroupIds}
                validateStatus={formItemValidateStatus(
                  userUpdateMutation.error,
                  'group_ids'
                )}
                help={formItemValidateMessage(
                  userUpdateMutation.error,
                  'group_ids'
                )}
              >
                <Select showSearch optionFilterProp="children" mode="multiple">
                  {user?.company?.groups?.map((group) => {
                    return (
                      <Select.Option key={group.id} value={group.id}>
                        {group.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="ユーザー権限"
                name="company_role"
                initialValue={user?.company_role}
                validateStatus={formItemValidateStatus(
                  userUpdateMutation.error,
                  'company_role'
                )}
                help={formItemValidateMessage(
                  userUpdateMutation.error,
                  'company_role'
                )}
              >
                <Select showSearch optionFilterProp="children">
                  {/* Adminとカンパニーマネージャーは許可 */}
                  <Select.Option
                    value={1}
                    disabled={
                      !userIsAdmin(auth.user) &&
                      !userIsCompanyManager(auth.user)
                    }
                  >
                    オーナー
                  </Select.Option>
                  <Select.Option value={2}>グループ管理者</Select.Option>
                  <Select.Option value={3}>一般</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UserEditModal;
