import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshUpdateCommentReq = {
  id: number;
  data: {
    memo_client_to_client: string;
    memo_operator_to_client: string;
    memo_operator_to_operator: string;
  };
};

export type WoshUpdateCommentRes = null;

const useWoshUpdateCommentMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, WoshUpdateCommentReq>(
    async (req: WoshUpdateCommentReq) => {
      return (
        await axiosInstance()
          .post<WoshUpdateCommentRes>(
            `/wosh/update_comment/${req.id}`,
            req.data
          )
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useWoshUpdateCommentMutation;
