import React from 'react';
import dayjs from 'dayjs';

type DateTimeProps = {
  value: string;
};

const DateTime: React.FC<DateTimeProps> = ({ value }) => (
  <>{dayjs(value).format('YYYY-MM-DD HH:mm')}</>
);
export default DateTime;
