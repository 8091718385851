import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Result,
  Row,
  Select,
  Table,
  Space,
  Popconfirm,
  notification,
} from 'antd';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined } from '@ant-design/icons';
import ContentHeader from '@/components/common/ContentHeader';
import ContentWrapper from '@/components/common/ContentWrapper';
import ContentBlock from '@/components/common/ContentBlock';
import UserAddModal from '@/pages/UserList/UserAddModal';
import UserEditModal from '@/pages/UserList/UserEditModal';
import useUserListQuery, { UserListReq } from '@/hooks/useUserListQuery';
import { User, userIsAdmin } from '@/utils/user';
import UserGroupUsers from '@/components/user/UserGroupUsers';
import useAuth from '@/hooks/useAuth';
import UserCompanyRole from '@/components/user/UserCompanyRole';
import useCompanyListQuery from '@/hooks/useCompanyListQuery';
import useGroupListQuery, { GroupListReq } from '@/hooks/useGroupListQuery';
import { formError } from '@/utils/form';
import userUserDeleteMutation from '@/hooks/userUserDeleteMutation';

const productListContentStyle = css`
  padding: 0;
  margin-top: 50px;
`;

const headerRightStyle = css`
  padding: 0 44px;
`;

export type UserListFormValues = {
  search: string;
  id__exact?: number;
  company_id__exact?: number;
  group_ids?: number[];
  ordering: string;
  page: number;
  page_size: number;
};

const UserList: React.FC = () => {
  const auth = useAuth();

  // userAddModal
  const [userAddModalVisible, setUserAddModalVisible] =
    useState<boolean>(false);

  // userEditModal
  const [userEditModalUser, setUserEditModalUser] = useState<User | null>(null);

  // company
  const companyListQuery = useCompanyListQuery({
    ordering: 'id',
    expand: 'groups',
  });
  const companies = companyListQuery.data?.results;

  // groups
  const [groupListReq, setGroupListReq] = useState<GroupListReq>({
    ordering: 'id',
  });
  const groupListQuery = useGroupListQuery(groupListReq);
  const groups = groupListQuery.data?.results;

  // users
  const [userListForm] = useForm<UserListFormValues>();
  const [userListReq, setUserListReq] = useState<UserListReq>({
    page_size: 100,
    is_company: true,
    expand: 'company,company.groups,group_users,group_users.group',
    ordering: '-created_at',
  });
  const userListQuery = useUserListQuery(userListReq);
  const users = userListQuery.data?.results;
  const handleUserList = async () => {
    const values = userListForm.getFieldsValue(true);
    await setUserListReq({
      ...userListReq,
      id__exact: values.id__exact,
      search: values.search,
      company_id__exact: values.company_id__exact,
      group_ids: values.group_ids,
      ordering: values.ordering,
      page: values.page,
      page_size: values.page_size,
    });
  };
  const handleCompanyChange = async (value: number) => {
    if (value) {
      await setGroupListReq({
        ...groupListReq,
        company_id__exact: value,
        ordering: 'id',
      });
      await groupListQuery.refetch();
    }
  };

  // userDelete
  const userDeleteMutation = userUserDeleteMutation();
  const handleUserDelete = (id: number) => {
    userDeleteMutation.mutate(id, {
      onSuccess: async () => {
        notification.success({
          message: '削除しました。',
        });
        await userListQuery.refetch();
      },
      onError: formError,
    });
  };

  return (
    <>
      <Helmet>
        <title>ユーザー一覧</title>
      </Helmet>
      <ContentWrapper style={productListContentStyle}>
        <ContentBlock>
          <ContentHeader title="ユーザー一覧">
            <div className={headerRightStyle}>
              {auth.user?.globalAbilities?.can_user_create && (
                <Button
                  type="primary"
                  shape="round"
                  ghost
                  onClick={() => {
                    setUserAddModalVisible(true);
                  }}
                >
                  ユーザー追加
                </Button>
              )}
            </div>
          </ContentHeader>
          <Collapse>
            <Collapse.Panel key="1" header="検索">
              <Form
                layout="vertical"
                onFinish={handleUserList}
                form={userListForm}
              >
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="検索" name="search">
                      <Input placeholder="名前、メールアドレス、電話番号" />
                    </Form.Item>
                  </Col>
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="ID" name="id__exact">
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  {userIsAdmin(auth.user) && (
                    <Col xs={24} lg={8}>
                      <Form.Item label="組織" name="company_id__exact">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          allowClear
                          onChange={handleCompanyChange}
                        >
                          {companies?.map((tmp_company) => (
                            <Select.Option
                              key={tmp_company.id}
                              value={tmp_company.id}
                            >
                              {tmp_company.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} lg={8}>
                    <Form.Item label="グループ" name="group_ids">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        mode="multiple"
                      >
                        {groups?.map((group) => {
                          return (
                            <Select.Option key={group.id} value={group.id}>
                              {group.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                      label="並び順"
                      name="ordering"
                      initialValue="-created_at"
                    >
                      <Select showSearch optionFilterProp="children">
                        <Select.Option value="created_at">
                          作成日時 : 昇順
                        </Select.Option>
                        <Select.Option value="-created_at">
                          作成日時 : 降順
                        </Select.Option>
                        <Select.Option value="name">名前 : 昇順</Select.Option>
                        <Select.Option value="-name">名前 : 降順</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={userListQuery.isLoading}
                  >
                    検索
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      userListForm.resetFields();
                      userListForm.submit();
                    }}
                  >
                    リセット
                  </Button>
                </Space>
              </Form>
            </Collapse.Panel>
          </Collapse>
          {userListQuery.isError && (
            <Result status="warning" title="データの読み込みに失敗しました" />
          )}
          {userListQuery.isSuccess && (
            <Table
              rowKey="id"
              dataSource={users}
              scroll={{ x: 900 }}
              loading={userListQuery.isLoading}
              pagination={{
                position: ['topRight', 'bottomRight'],
                current: userListReq.page,
                total: userListQuery.data?.count,
                pageSize: userListReq.page_size,
                showTotal: (total: number, range: number[]) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: async (page, page_size) => {
                  userListForm.setFieldsValue({
                    page: page_size === userListReq.page_size ? page : 1,
                    page_size,
                  });
                  await handleUserList();
                },
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '500'],
              }}
            >
              {userIsAdmin(auth.user) && (
                <Table.Column title="ID" dataIndex="id" key="id" />
              )}
              {userIsAdmin(auth.user) && (
                <Table.Column
                  title="組織"
                  key="company"
                  render={(_, user: User) => user.company?.name}
                />
              )}
              <Table.Column
                title="グループ"
                key="group"
                render={(_, user: User) => <UserGroupUsers user={user} />}
              />
              <Table.Column title="名前" dataIndex="name" key="name" />
              <Table.Column
                title="権限"
                dataIndex="role"
                key="role"
                render={(_, user: User) => <UserCompanyRole user={user} />}
              />
              <Table.Column
                title="メールアドレス"
                dataIndex="email"
                key="email"
              />
              <Table.Column
                title="電話番号"
                dataIndex="phone_number"
                key="phone_number"
              />
              <Table.Column
                title=""
                key="edit"
                render={(_, user: User) => (
                  <>
                    <Space>
                      {user?.abilities?.can_user_update && (
                        <Button
                          type="primary"
                          shape="round"
                          ghost
                          onClick={() => {
                            setUserEditModalUser(user);
                          }}
                        >
                          EDIT
                        </Button>
                      )}
                      {user?.abilities?.can_user_delete && (
                        <Popconfirm
                          title={`${user.name}を削除しますか？`}
                          okText="削除する"
                          cancelText="キャンセル"
                          onConfirm={() => handleUserDelete(user.id)}
                        >
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      )}
                    </Space>
                    <UserEditModal
                      user={user}
                      visible={user === userEditModalUser}
                      onFinish={async () => {
                        setUserEditModalUser(null);
                        await userListQuery.refetch();
                      }}
                      onCancel={() => setUserEditModalUser(null)}
                    />
                  </>
                )}
              />
            </Table>
          )}
        </ContentBlock>
      </ContentWrapper>
      <UserAddModal
        companies={companies}
        visible={userAddModalVisible}
        onFinish={async () => {
          await userListQuery.refetch();
          setUserAddModalVisible(false);
        }}
        onCancel={() => setUserAddModalVisible(false)}
      />
    </>
  );
};

export default UserList;
