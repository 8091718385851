import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import {
  BookIcon,
  CartIcon,
  HomeIcon,
  ListIcon,
  QaIcon,
} from '@/components/common/Icon';
import { ReactComponent as IconBlank } from '@/assets/img/icon_blank.svg';
import useAuth from '@/hooks/useAuth';

const { Sider } = Layout;

const siderStyle = css`
  min-height: 684px;
  background: transparent
    linear-gradient(171deg, #37c8d6 0%, #26b4de 51%, #39fef4 100%) 0% 0%
    no-repeat padding-box;
`;

const SideMenu: React.FC = () => {
  const [currentNav, setCurrentNav] = useState('1');
  const auth = useAuth();
  const isDomainWoshAdmin = document.domain === 'wosh-admin.jp';
  const manualLink = isDomainWoshAdmin
    ? 'https://www.notion.so/621aa50cf93e4ea18c22abc9d7b66450'
    : 'https://wota.notion.site/302f1ab3e99e402997dfb79c8d9a7818';

  return (
    <Sider
      width={260}
      className={siderStyle}
      breakpoint="lg"
      collapsedWidth="0"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        className="side-menu"
        onClick={(e) => setCurrentNav(String(e.key))}
      >
        <Menu.Item
          key="home"
          icon={<HomeIcon style={currentNav === '1' ? { fill: '#fff' } : {}} />}
        >
          <Link to="/">HOME</Link>
        </Menu.Item>
        <Menu.Item
          key="wosh"
          icon={<ListIcon style={currentNav === '2' ? { fill: '#fff' } : {}} />}
        >
          <Link to="/wosh">製品一覧</Link>
        </Menu.Item>
        <Menu.Item
          key="group"
          icon={<ListIcon style={currentNav === '2' ? { fill: '#fff' } : {}} />}
        >
          <Link to="/group">グループ一覧</Link>
        </Menu.Item>
        {auth.user?.globalAbilities?.can_company_show && (
          <Menu.Item
            key="company"
            icon={
              <ListIcon style={currentNav === '2' ? { fill: '#fff' } : {}} />
            }
          >
            <Link to="/company">組織一覧</Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="user"
          icon={<ListIcon style={currentNav === '2' ? { fill: '#fff' } : {}} />}
        >
          <Link to="/user">ユーザー一覧</Link>
        </Menu.Item>
        {auth.user?.globalAbilities?.can_admin_user_show && (
          <Menu.Item
            key="admin"
            icon={
              <ListIcon style={currentNav === '2' ? { fill: '#fff' } : {}} />
            }
          >
            <Link to="/admin">Admin一覧</Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="3"
          icon={<BookIcon style={currentNav === '3' ? { fill: '#fff' } : {}} />}
        >
          <a href={manualLink} target="_blank" rel="noopener noreferrer">
            <div className="flex items-center">
              <span>利用マニュアル</span>
              <IconBlank className="ml-2" />
            </div>
          </a>
        </Menu.Item>
        {!isDomainWoshAdmin && (
          <>
            <Menu.Item
              key="4"
              icon={
                <QaIcon style={currentNav === '4' ? { fill: '#fff' } : {}} />
              }
            >
              <a
                href="https://wota.notion.site/WOTA-316087d0422d4c7e8da62521da71175c"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center">
                  <span>Q&A・お問い合わせ</span>
                  <IconBlank className="ml-2" />
                </div>
              </a>
            </Menu.Item>
            <Menu.Item
              key="5"
              icon={
                <CartIcon style={currentNav === '5' ? { fill: '#fff' } : {}} />
              }
            >
              <a
                href="https://store.wota.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center">
                  <span>消耗品の購入</span>
                  <IconBlank className="ml-2" />
                </div>
              </a>
            </Menu.Item>
          </>
        )}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
