import React, { FC } from 'react';
import { Tag } from 'antd';
import {
  StatusSuccessIcon,
  StatusErrorIcon,
  StatusMaintenanceIcon,
} from '@/components/common/Icon';
import { Wosh, WoshStatusLevels } from '@/utils/wosh';

type WoshStatusTagProps = {
  wosh: Wosh;
};

const WoshStatusTag: FC<WoshStatusTagProps> = ({ wosh }) => {
  let config: {
    text: string;
    icon: React.ReactNode;
    color: string;
  };
  switch (wosh.status_level) {
    case WoshStatusLevels.normal:
      config = {
        text: '正常運転中',
        icon: <StatusSuccessIcon />,
        color: '#39d94c',
      };
      break;
    case WoshStatusLevels.error:
      config = {
        text: 'エラー',
        icon: <StatusErrorIcon />,
        color: '#e35c5c',
      };
      break;
    case WoshStatusLevels.prepare:
      config = {
        text: 'お手入れ（準備）',
        icon: <StatusMaintenanceIcon />,
        color: '#ffa500',
      };
      break;
    case WoshStatusLevels.urgent:
      config = {
        text: 'お手入れ（至急）',
        icon: <StatusMaintenanceIcon />,
        color: '#e35c5c',
      };
      break;
    default:
      return null;
  }
  return (
    <Tag icon={config.icon} color={config.color}>
      {config.text}
    </Tag>
  );
};

export default WoshStatusTag;
