import React, { useCallback, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import ContentHeader from '@/components/common/ContentHeader';
import ContentWrapper from '@/components/common/ContentWrapper';
import ContentBlock from '@/components/common/ContentBlock';
import useCompanyListQuery, {
  CompanyListReq,
} from '@/hooks/useCompanyListQuery';
import { Group } from '@/utils/group';
import { Wosh } from '@/utils/wosh';
import { User } from '@/utils/user';
import useAuth from '@/hooks/useAuth';
import CompanyAddModal from '@/pages/CompanyList/CompanyAddModal';
import { Company } from '@/utils/company';
import CompanyEditModal from '@/pages/CompanyList/CompanyEditModal';
import useCompanyDeleteMutation, {
  CompanyDeleteMutationVariables,
} from '@/hooks/useCompanyDeleteMutation';

const productListContentStyle = css`
  padding: 0;
  margin-top: 50px;
`;

const headerRightStyle = css`
  padding: 0 44px;
`;

export type CompanyListFormValues = {
  id__exact?: number;
  name__contains?: string;
  ordering: string;
  page: number;
  page_size: number;
};

const CompanyList: React.FC = () => {
  // companies
  const [companyListForm] = useForm<CompanyListFormValues>();
  const [companyListReq, setCompanyListReq] = useState<CompanyListReq>({
    ordering: '-id',
    page: 1,
    page_size: 100,
    expand: 'groups,users,woshes',
  });
  const companyListQuery = useCompanyListQuery(companyListReq);
  const companies = companyListQuery.data?.results;
  const handleCompanyList = async () => {
    const values = companyListForm.getFieldsValue(true);
    await setCompanyListReq({
      ...companyListReq,
      id__exact: values.id__exact,
      name__contains: values.name__contains,
      ordering: values.ordering,
      page: values.page,
      page_size: values.page_size,
    });
  };
  const handleCompanyFormClear = () => {
    companyListForm.resetFields();
    companyListForm.submit();
  };
  const auth = useAuth();
  // companyAddModal
  const [companyAddModalVisible, setCompanyAddModalVisible] =
    useState<boolean>(false);

  // Edit Company
  const [selectedCompanyForEdit, setSelectedCompanyForEdit] =
    useState<Company | null>(null);
  const clickEditButton = (record: Company) => {
    setSelectedCompanyForEdit(record);
  };
  const onCompanyEditModalFinish = useCallback(async () => {
    await companyListQuery.refetch();
    setSelectedCompanyForEdit(null);
  }, []);
  const onCompanyEditModalCancel = useCallback(() => {
    setSelectedCompanyForEdit(null);
  }, []);

  // Delete Company
  const companyDeleteMutation = useCompanyDeleteMutation();
  const handleCompanyDelete = (
    companyId: CompanyDeleteMutationVariables['companyDeletePathParam']['id']
  ) => {
    const mutationVariables: CompanyDeleteMutationVariables = {
      companyDeletePathParam: {
        id: companyId,
      },
    };
    companyDeleteMutation.mutate(mutationVariables, {
      onSuccess: async () => {
        notification.success({
          message: '削除しました',
        });
        await companyListQuery.refetch();
      },
      onError: (e: AxiosError) => {
        if (!e.response) return;

        notification.error({
          message: e.response.data.error,
          duration: 8,
        });
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>組織一覧</title>
      </Helmet>
      <ContentWrapper style={productListContentStyle}>
        <ContentBlock>
          <ContentHeader title="組織一覧">
            <div className={headerRightStyle}>
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => {
                  setCompanyAddModalVisible(true);
                }}
                disabled={!auth.user?.globalAbilities?.can_company_create}
              >
                組織追加
              </Button>
            </div>
          </ContentHeader>
          <Collapse>
            <Collapse.Panel key="1" header="検索">
              <Form<CompanyListFormValues>
                layout="vertical"
                form={companyListForm}
                onFinish={handleCompanyList}
              >
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="ID" name="id__exact">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="名称" name="name__contains">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="並び順" name="ordering" initialValue="id">
                      <Select showSearch optionFilterProp="children">
                        <Select.Option value="id">ID : 昇順</Select.Option>
                        <Select.Option value="-id">ID : 降順</Select.Option>
                        <Select.Option value="name">名前 : 昇順</Select.Option>
                        <Select.Option value="-name">名前 : 降順</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={companyListQuery.isLoading}
                  >
                    検索
                  </Button>
                  <Button htmlType="button" onClick={handleCompanyFormClear}>
                    リセット
                  </Button>
                </Space>
              </Form>
            </Collapse.Panel>
          </Collapse>
          {companyListQuery.isError && (
            <Result status="warning" title="データの読み込みに失敗しました" />
          )}
          {companyListQuery.isSuccess && (
            <Table
              rowKey="id"
              dataSource={companies}
              scroll={{ x: 900 }}
              loading={companyListQuery.isLoading}
              pagination={{
                position: ['topRight', 'bottomRight'],
                current: companyListReq.page,
                total: companyListQuery.data?.count,
                pageSize: companyListReq.page_size,
                showTotal: (total: number, range: number[]) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: async (page, page_size) => {
                  companyListForm.setFieldsValue({
                    page: page_size === companyListReq.page_size ? page : 1,
                    page_size,
                  });
                  await handleCompanyList();
                },
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '500'],
              }}
            >
              <Table.Column title="ID" dataIndex="id" key="id" />
              <Table.Column title="名称" dataIndex="name" key="name" />
              <Table.Column
                title="グループ数"
                dataIndex="groups"
                key="groups"
                render={(groups: Group[]) => {
                  return groups?.length;
                }}
              />
              <Table.Column
                title="ユーザー数"
                dataIndex="users"
                key="users"
                render={(users: User[]) => {
                  return users?.length;
                }}
              />
              <Table.Column
                title="wosh数"
                dataIndex="woshes"
                key="woshes"
                render={(woshes: Wosh[]) => {
                  return woshes?.length;
                }}
              />
              <Table.Column
                title=""
                key="edit"
                render={(_, tableRecord: Company) => (
                  <Space>
                    {tableRecord.abilities?.can_company_update && (
                      <Button
                        type="primary"
                        shape="round"
                        ghost
                        onClick={() => clickEditButton(tableRecord)}
                        disabled={!tableRecord.abilities?.can_company_update}
                      >
                        EDIT
                      </Button>
                    )}
                    {tableRecord?.abilities?.can_company_delete && (
                      <Popconfirm
                        placement="topRight"
                        title={`${tableRecord.name}を削除しますか？`}
                        disabled={!tableRecord?.abilities?.can_company_delete}
                        okText="削除する"
                        cancelText="キャンセル"
                        onConfirm={() => handleCompanyDelete(tableRecord.id)}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                  </Space>
                )}
              />
            </Table>
          )}
        </ContentBlock>
      </ContentWrapper>
      <CompanyAddModal
        visible={companyAddModalVisible}
        onFinish={async () => {
          await companyListQuery.refetch();
          setCompanyAddModalVisible(false);
        }}
        onCancel={() => setCompanyAddModalVisible(false)}
      />
      <CompanyEditModal
        visible={!!selectedCompanyForEdit}
        company={selectedCompanyForEdit}
        onFinish={onCompanyEditModalFinish}
        onCancel={onCompanyEditModalCancel}
      />
    </>
  );
};

export default CompanyList;
