import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type GroupUpdateReq = {
  id: number;
  name: string;
  contract: string;
};

export type GroupUpdateRes = null;

const useGroupUpdateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, GroupUpdateReq>(
    async (req: GroupUpdateReq) => {
      const { id, ...rest } = req;
      return (
        await axiosInstance()
          .post<GroupUpdateRes>(`/group/update/${id}`, rest)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useGroupUpdateMutation;
