import { Button, Form, Modal, notification, Input } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useGroupUpdateMutation, {
  GroupUpdateReq,
} from '@/hooks/useGroupUpdateMutation';
// import { userIsAdmin, userIsCompanyManager } from '@/utils/user';
import {
  formError,
  formItemValidateMessage,
  formItemValidateStatus,
} from '@/utils/form';
import { Group } from '@/utils/group';

type GroupEditModalProps = {
  visible: boolean;
  group: Group;
  onFinish: () => void;
  onCancel: () => void;
};

const GroupEditModal: React.FC<GroupEditModalProps> = ({
  visible,
  group,
  onFinish,
  onCancel,
}) => {
  const [groupUpdateForm] = useForm();
  const groupUpdateMutation = useGroupUpdateMutation();
  const handleGroupUpdate = (values: any) => {
    const req: GroupUpdateReq = {
      id: group.id,
      name: values.name,
      contract: values.contract,
    };
    groupUpdateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        onFinish();
      },
      onError: formError,
    });
  };

  return (
    <Modal
      footer={null}
      onCancel={onCancel}
      visible={visible}
      title="グループ更新"
    >
      <Form onFinish={handleGroupUpdate} form={groupUpdateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="名前"
            name="name"
            initialValue={group?.name}
            validateStatus={formItemValidateStatus(
              groupUpdateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(groupUpdateMutation.error, 'name')}
          >
            <Input size="large" placeholder="名前を入力してください" />
          </Form.Item>
          <Form.Item
            label="連絡先"
            name="contract"
            initialValue={group?.contract}
            validateStatus={formItemValidateStatus(
              groupUpdateMutation.error,
              'contract'
            )}
            help={formItemValidateMessage(
              groupUpdateMutation.error,
              'contract'
            )}
          >
            <Input size="large" placeholder="連絡先を入力してください" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default GroupEditModal;
