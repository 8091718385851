import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshUpdatePlaceReq = {
  id: number;
  data: {
    place: string;
  };
};

export type WoshUpdatePlaceRes = null;

const useWoshUpdatePlaceMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, WoshUpdatePlaceReq>(
    async (req: WoshUpdatePlaceReq) => {
      return (
        await axiosInstance()
          .post<WoshUpdatePlaceRes>(`/wosh/update_place/${req.id}`, req.data)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useWoshUpdatePlaceMutation;
