import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshUpdateGroupReq = {
  id: number;
  group: number;
};

export type WoshUpdateGroupRes = null;

const useWoshUpdateGroupMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, WoshUpdateGroupReq>(
    async (req: WoshUpdateGroupReq) => {
      const { id, ...rest } = req;
      return (
        await axiosInstance()
          .post<WoshUpdateGroupRes>(`/wosh/update_group/${id}`, rest)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useWoshUpdateGroupMutation;
