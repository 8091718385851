import React from 'react';
import { css } from '@emotion/css';
import { Form, Input, Button, notification } from 'antd';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '@/assets/img/logo_w.svg';
import useAuthLoginMutation from '@/hooks/useAuthLoginMutation';
import { formItemValidateMessage, formItemValidateStatus } from '@/utils/form';
import useAuth from '@/hooks/useAuth';

const wrapperStyle = css`
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: transparent
    linear-gradient(171deg, #37c8d6 0%, #26b4de 51%, #39fef4 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const blockStyle = css`
  max-width: 450px;
  width: 100%;
  margin: auto;
  text-align: center;
`;

const logoStyle = css`
  display: block;
  max-width: 312px;
  margin: 0 auto 70px;
  max-width: 100%;
`;

const inputStyle = css`
  padding: 20px 24px;
`;

const authLoginFormItemStyle = css`
  position: relative;

  &.ant-form-item-has-error {
    .ant-input {
      background-color: #f5dede;
    }
  }

  &.ant-form-item-with-help {
    margin-bottom: 24px;
  }
  .ant-form-item-explain-error {
    position: absolute;
    top: 50%;
    left: calc(100% + 20px);
    transform: translateY(-50%);
    text-align: left;
    color: #fff;
    font-size: 11px;
    width: 100%;
    max-width: 106px;
  }
`;

const formBottomStyle = css`
  margin-top: 40px;
  a {
    color: #fff;
    font-size: 15px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type AuthLoginFormValues = {
  email: string;
  password: string;
};

const authLoginFormInitialValues: AuthLoginFormValues = {
  email: '',
  password: '',
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const authLoginMutation = useAuthLoginMutation();
  const auth = useAuth();
  const handleAuthLogin = (values: AuthLoginFormValues) => {
    authLoginMutation.mutate(
      {
        email: values.email,
        password: values.password,
      },
      {
        async onSuccess(res) {
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          notification.success({
            message: 'ログインしました。',
          });
          await auth.login();
          navigate('/');
        },
        onError() {
          notification.error({
            message: 'ログイン出来ませんでした。',
          });
        },
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>ログイン</title>
      </Helmet>
      <div className={wrapperStyle}>
        <div className={blockStyle}>
          <Logo className={logoStyle} />
          <Form<AuthLoginFormValues>
            name="login"
            initialValues={authLoginFormInitialValues}
            onFinish={handleAuthLogin}
          >
            <Form.Item
              name="email"
              className={authLoginFormItemStyle}
              validateStatus={formItemValidateStatus(
                authLoginMutation.error,
                'email'
              )}
              help={formItemValidateMessage(authLoginMutation.error, 'email')}
            >
              <Input
                type="email"
                placeholder="メールアドレス ※半角英数で入力してください"
                className={inputStyle}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className={authLoginFormItemStyle}
              validateStatus={formItemValidateStatus(
                authLoginMutation.error,
                'email'
              )}
              help={formItemValidateMessage(authLoginMutation.error, 'email')}
            >
              <Input
                type="password"
                placeholder="パスワード"
                className={inputStyle}
                autoComplete="on"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                shape="round"
                className="button-green"
                style={{
                  width: '100%',
                  maxWidth: '186px',
                  padding: '10px 16px',
                  height: 'auto',
                }}
              >
                ログイン
              </Button>
              <div className={formBottomStyle}>
                <Link to="/passwordReset">パスワードを忘れた場合</Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
