import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

const SEO: FC = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
  );
};

export default SEO;
