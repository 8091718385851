import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu';

// 参考:
// https://ant.design/components/layout/#components-layout-demo-top-side-2
const AppLayout: React.FC = () => (
  <Layout>
    <Header />
    <Layout>
      <SideMenu />
      <Layout>
        <Outlet />
        <Footer />
      </Layout>
    </Layout>
  </Layout>
);
export default AppLayout;
