import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

type WoshDeletePathParam = {
  id: number;
};

type WoshDeleteRespBody = null;

export type WoshDeleteMutationVariables = {
  woshDeletePathParam: WoshDeletePathParam;
};

const deleteWosh = async (woshDeletePathParam: WoshDeletePathParam) => {
  const { id: woshId } = woshDeletePathParam;
  const axiosResponse = await axiosInstance().post<WoshDeleteRespBody>(
    `/wosh/delete/${woshId}`
  );

  return axiosResponse.data;
};

const useWoshDeleteMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();

  return useMutation<
    WoshDeleteRespBody,
    AxiosError<ErrorRes>,
    WoshDeleteMutationVariables
  >(async (mutationVariables) => {
    const { woshDeletePathParam } = mutationVariables;

    try {
      return await deleteWosh(woshDeletePathParam);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosErrorHandler(error);
        throw error;
      }

      throw error;
    }
  });
};

export default useWoshDeleteMutation;
