import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

const useUserDeleteMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, number>(async (id: number) => {
    return (
      await axiosInstance()
        .post(`/user/delete/${id}`)
        .catch((error: AxiosError) => {
          axiosErrorHandler(error);
          throw error;
        })
    ).data;
  });
};

export default useUserDeleteMutation;
