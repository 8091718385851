/* eslint-disable react/require-default-props */
import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const contentWrapperStyle = {
  padding: '0 50px',
};

type ContentWrapperProps = {
  style?: string;
  children: React.ReactNode;
};

type ContentWrapperComponent = (props: ContentWrapperProps) => JSX.Element;

const ContentWrapper: ContentWrapperComponent = ({
  style,
  children,
}: ContentWrapperProps) => (
  <Content className={style} style={contentWrapperStyle}>
    {children}
  </Content>
);

export default ContentWrapper;
