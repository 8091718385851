/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as BookSvg } from './icons/book.svg';
import { ReactComponent as CartSvg } from './icons/cart.svg';
import { ReactComponent as HomeSvg } from './icons/home.svg';
import { ReactComponent as ListSvg } from './icons/list.svg';
import { ReactComponent as QaSvg } from './icons/qa.svg';
import { ReactComponent as LogoutSvg } from './icons/logout.svg';
import { ReactComponent as UserSvg } from './icons/user.svg';
import { ReactComponent as KeySvg } from './icons/key.svg';
import { ReactComponent as StatusSuccessSvg } from './icons/status_success.svg';
import { ReactComponent as StatusErrorSvg } from './icons/status_error.svg';
import { ReactComponent as StatusMaintenanceSvg } from './icons/status_maintenance.svg';
import { ReactComponent as ActiveOnSvg } from './icons/active_on.svg';
import { ReactComponent as ActiveOffSvg } from './icons/active_off.svg';

type IconProps = {
  className?: string;
  style?: React.CSSProperties;
};

type IconComponent = (props: IconProps) => JSX.Element;

const sideIconStyle = {
  display: 'inline-flex',
  width: '21px',
  height: '21px',
  alignItems: 'center',
  justifyContent: 'center',
};

export const BookIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <BookSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
    style={sideIconStyle}
  />
);

export const CartIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <CartSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
    style={sideIconStyle}
  />
);

export const HomeIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <HomeSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
    style={sideIconStyle}
  />
);

export const ListIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <ListSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
    style={sideIconStyle}
  />
);

export const QaIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <QaSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
    style={sideIconStyle}
  />
);

export const LogoutIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <LogoutSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const UserIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <UserSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const KeyIcon: IconComponent = ({ className, style }: IconProps) => (
  <Icon
    component={memo(() => (
      <KeySvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const StatusSuccessIcon: IconComponent = ({
  className,
  style,
}: IconProps) => (
  <Icon
    component={memo(() => (
      <StatusSuccessSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const StatusErrorIcon: IconComponent = ({
  className,
  style,
}: IconProps) => (
  <Icon
    component={memo(() => (
      <StatusErrorSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const StatusMaintenanceIcon: IconComponent = ({
  className,
  style,
}: IconProps) => (
  <Icon
    component={memo(() => (
      <StatusMaintenanceSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const ActiveOnIcon: IconComponent = ({
  className,
  style,
}: IconProps) => (
  <Icon
    component={memo(() => (
      <ActiveOnSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);

export const ActiveOffIcon: IconComponent = ({
  className,
  style,
}: IconProps) => (
  <Icon
    component={memo(() => (
      <ActiveOffSvg style={style ?? {}} />
    ))}
    className={className ?? ''}
  />
);
