import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import HeaderAccountMenu from '@/layouts/AppLayout/HeaderAccountMenu';
import { ReactComponent as Logo } from '@/assets/img/logo.svg';

const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header: React.FC = () => {
  return (
    <Layout.Header className={headerStyle}>
      <Link className="logo" to="/">
        <Logo />
      </Link>
      <HeaderAccountMenu />
    </Layout.Header>
  );
};

export default Header;
