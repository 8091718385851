import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { WoshError } from '@/utils/wosh_error';
import { ListRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshErrorListReq = {
  ordering?: string;
  expand?: string;
};

export type WoshErrorListRes = ListRes & {
  results: WoshError[];
};

const useWoshErrorListQuery = (req: WoshErrorListReq) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['woshErrorList', req],
    async () => {
      return (
        await axiosInstance()
          .get<WoshErrorListRes>('/wosh_error', {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    {}
  );
};

export default useWoshErrorListQuery;
