import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { Button, Form, Input, notification, Spin } from 'antd';
import usePasswordResetQuery from '@/hooks/useAuthPasswordResetQuery';
import { ReactComponent as Logo } from '@/assets/img/logo_w.svg';
import useAuthPasswordChangeMutation from '@/hooks/useAuthPasswordChangeMutation';
import { formItemValidateMessage, formItemValidateStatus } from '@/utils/form';

const wrapperStyle = css`
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: transparent
    linear-gradient(171deg, #37c8d6 0%, #26b4de 51%, #39fef4 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const blockStyle = css`
  max-width: 500px;
  width: 100%;
  margin: auto;
  text-align: center;
`;

const logoStyle = css`
  display: block;
  max-width: 312px;
  margin: 0 auto 70px;
  max-width: 100%;
`;

const inputStyle = css`
  padding: 20px 24px;
`;

const authPasswordChangeFormItemStyle = css`
  position: relative;

  &.ant-form-item-has-error {
    .ant-input {
      background-color: #f5dede;
    }
  }

  &.ant-form-item-with-help {
    margin-bottom: 24px;
  }
  .ant-form-item-explain-error {
    position: absolute;
    top: 50%;
    left: calc(100% + 20px);
    transform: translateY(-50%);
    text-align: left;
    color: #fff;
    font-size: 11px;
    width: 100%;
    max-width: 106px;
  }
`;

const formBottomStyle = css`
  margin-top: 40px;
  a {
    color: #fff;
    font-size: 15px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type AuthPasswordChangeFormValues = {
  password: string;
};

const authPasswordChangeFormInitialValues: AuthPasswordChangeFormValues = {
  password: '',
};

const PasswordResetConfirm: React.FC = () => {
  const { uidb64, token } = useParams();
  const passwordResetQuery = usePasswordResetQuery(
    uidb64 as string,
    token as string
  );
  useEffect(() => {
    if (passwordResetQuery.isError) {
      notification.error({
        message: 'パスワード変更はできません。',
      });
    }
  });

  const authPasswordChangeMutation = useAuthPasswordChangeMutation();
  const handleAuthPasswordChange = (values: AuthPasswordChangeFormValues) => {
    if (uidb64 && token) {
      authPasswordChangeMutation.mutate(
        {
          uidb64,
          token,
          password: values.password,
        },
        {
          async onSuccess() {
            notification.success({
              message: 'パスワードの変更しました。',
            });
          },
          onError() {
            notification.error({
              message: 'パスワードの変更に失敗しました。',
            });
          },
        }
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>パスワード再設定</title>
      </Helmet>
      <Spin spinning={passwordResetQuery.isLoading}>
        <div className={wrapperStyle}>
          <div className={blockStyle}>
            <Logo className={logoStyle} />
            <Form<AuthPasswordChangeFormValues>
              name="passwordChange"
              initialValues={authPasswordChangeFormInitialValues}
              onFinish={handleAuthPasswordChange}
            >
              {!passwordResetQuery.isError && (
                <>
                  <Form.Item
                    name="password"
                    className={authPasswordChangeFormItemStyle}
                    validateStatus={formItemValidateStatus(
                      authPasswordChangeMutation.error,
                      'password'
                    )}
                    help={formItemValidateMessage(
                      authPasswordChangeMutation.error,
                      'password'
                    )}
                  >
                    <Input
                      type="password"
                      placeholder="パスワード"
                      className={inputStyle}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      shape="round"
                      className="button-green"
                      style={{
                        width: '100%',
                        maxWidth: '186px',
                        padding: '10px 16px',
                        height: 'auto',
                      }}
                    >
                      パスワード変更
                    </Button>
                  </Form.Item>
                </>
              )}
              <div className={formBottomStyle}>
                <a href="/login">ログイン画面</a>
              </div>
              <div className={formBottomStyle}>
                <a href="/passwordReset">メールを再送する</a>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default PasswordResetConfirm;
