import React, { FC } from 'react';
import { css } from '@emotion/css';

const contentHeaderStyle = css`
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
`;

const contentHeaderTitleStyle = css`
  padding: 30px 44px;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  line-height: 1;
`;

type ContentHeaderProps = {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
};

const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  subTitle,
  children,
}: ContentHeaderProps) => (
  <div className={contentHeaderStyle}>
    <h2 className={contentHeaderTitleStyle}>{title}</h2>
    {subTitle && <span>{subTitle}</span>}
    {children && <div>{children}</div>}
  </div>
);

export default ContentHeader;
