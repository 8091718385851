import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Group } from '@/utils/group';
import { ListReq, ListRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type GroupListReq = ListReq & {
  search?: string;
  id__exact?: number;
  company_id__exact?: number;
};

export type GroupListRes = ListRes & {
  results: Group[];
};

const useGroupListQuery = (req: GroupListReq, options?: any) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['groupList', req],
    async () => {
      return (
        await axiosInstance()
          .get<GroupListRes>('/group', {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    options
  );
};

export default useGroupListQuery;
