import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type AuthPasswordResetReq = {
  email: string;
};

export type AuthPasswordResetRes = {
  result: boolean;
};

const useAuthPasswordResetMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<
    AuthPasswordResetRes,
    AxiosError<ErrorRes>,
    AuthPasswordResetReq
  >(async (req: AuthPasswordResetReq): Promise<AuthPasswordResetRes> => {
    return (
      await axiosInstance()
        .post<AuthPasswordResetRes>('/auth/password/reset', req)
        .catch((error: AxiosError) => {
          axiosErrorHandler(error);
          throw error;
        })
    ).data;
  });
};

export default useAuthPasswordResetMutation;
