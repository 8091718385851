import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { User } from '@/utils/user';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type AuthUserRes = User;

const useAuthUserQuery = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['authUser'],
    async () => {
      return (
        await axiosInstance()
          .get<AuthUserRes>('/auth/user')
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    // 未認証の場合の401レスポンスは正常。retryしない。
    {
      retry: false,
      enabled: false,
    }
  );
};

export default useAuthUserQuery;
