import React from 'react';
import { Helmet } from 'react-helmet-async';
import ContentWrapper from '@/components/common/ContentWrapper';
import WoshUsageStats from '@/components/wosh/WoshUsageStats';

const Home: React.FC = () => (
  <>
    <Helmet>
      <title>HOME</title>
    </Helmet>
    <ContentWrapper>
      <WoshUsageStats />
    </ContentWrapper>
  </>
);

export default Home;
