import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { CreatedRes, ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshCreateReq = {
  group: number;
  serial_number: string;
  device_id: string;
  place: string;
};

export type WoshCreateRes = CreatedRes;

const useWoshCreateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<WoshCreateRes, AxiosError<ErrorRes>, WoshCreateReq>(
    async (req: WoshCreateReq): Promise<WoshCreateRes> => {
      return (
        await axiosInstance()
          .post<WoshCreateRes>('/wosh/create', req)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useWoshCreateMutation;
