import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type AuthLoginReq = {
  email: string;
  password: string;
};

export type AuthLoginRes = {
  access_token: string;
  refresh_token: string;
};

const useAuthLoginMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<AuthLoginRes, AxiosError<ErrorRes>, AuthLoginReq>(
    async (req: AuthLoginReq): Promise<AuthLoginRes> => {
      return (
        await axiosInstance()
          .post<AuthLoginRes>('/auth/login', req)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useAuthLoginMutation;
