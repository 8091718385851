import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type UserUpdateReq = {
  id: number;
  group_ids: number[];
  user_ids: number[];
  email: string;
  name: string;
  phone_number: string;
  company_role: number;
};

export type UserUpdateRes = null;

const useUserUpdateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, UserUpdateReq>(
    async (req: UserUpdateReq) => {
      const { id, ...rest } = req;
      return (
        await axiosInstance()
          .post<UserUpdateRes>(`/user/update/${id}`, rest)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useUserUpdateMutation;
