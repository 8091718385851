import React from 'react';
import { Table } from 'antd';
import ContentBlock from '@/components/common/ContentBlock';
import ContentHeader from '@/components/common/ContentHeader';
import { Wosh } from '@/utils/wosh';
import DateTime from '@/components/DateTime';
import {
  useWoshEventListQuery,
  WoshEventListReqQueryParam,
} from '@/hooks/useWoshEventListQuery';
import {
  WoshEvent,
  WoshEventType,
  WoshEventTypeKeyType,
} from '@/utils/wosh_event';

type Props = {
  wosh: Wosh;
};

const woshDetailContentStyle = {
  padding: 0,
  marginTop: '50px',
};

const WoshEventList: React.FC<Props> = ({ wosh }) => {
  // 表示は抑止のためデータ取得は行わない
  const reqQueryParam: WoshEventListReqQueryParam = {
    wosh: wosh?.id,
    ordering: '-timestamp',
  };
  const woshEventListQuery = useWoshEventListQuery(reqQueryParam);
  const woshEventList: WoshEvent[] | undefined =
    woshEventListQuery.data?.results;

  return (
    <ContentBlock id="maintenance" style={woshDetailContentStyle}>
      <ContentHeader
        title="メンテナンス履歴"
        subTitle="※フィルター交換・石鹸補充の履歴表示は現在開発中です"
      />
      <Table dataSource={woshEventList} rowKey="id">
        <Table.Column
          title="発生時刻"
          dataIndex="timestamp"
          key="timestamp"
          width={180}
          render={(timestamp) => {
            return <DateTime value={timestamp} />;
          }}
        />
        <Table.Column
          title="メンテナンス名"
          dataIndex="event_type"
          key="event_type"
          width={310}
          render={(eventType: WoshEventTypeKeyType) => {
            if (!Object.keys(WoshEventType).includes(eventType.toString())) {
              return '不明';
            }
            return WoshEventType[eventType];
          }}
        />
      </Table>
    </ContentBlock>
  );
};

export default WoshEventList;
