import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

type CompanyUpdatePathParam = {
  id: number;
};

type CompanyUpdateReqBody = {
  name: string;
};

type CompanyUpdateRespBody = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export type CompanyUpdateMutationVariables = {
  companyUpdatePathParam: CompanyUpdatePathParam;
  companyUpdateReqBody: CompanyUpdateReqBody;
};

const updateCompany = async (
  companyUpdatePathParam: CompanyUpdatePathParam,
  companyUpdateReqBody: CompanyUpdateReqBody
) => {
  const axiosResponse = await axiosInstance().post<CompanyUpdateRespBody>(
    `/company/update/${companyUpdatePathParam.id}`,
    companyUpdateReqBody
  );

  return axiosResponse.data;
};

const useCompanyUpdateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();

  return useMutation<
    CompanyUpdateRespBody,
    AxiosError<ErrorRes>,
    CompanyUpdateMutationVariables
  >(async (mutationVariables) => {
    const { companyUpdatePathParam, companyUpdateReqBody } = mutationVariables;

    try {
      return await updateCompany(companyUpdatePathParam, companyUpdateReqBody);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosErrorHandler(error);
        throw error;
      }

      throw error;
    }
  });
};

export default useCompanyUpdateMutation;
