import React from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import ContentWrapper from '@/components/common/ContentWrapper';
import WoshHeader from '@/components/wosh/WoshHeader';
import WoshDetailMain from '@/pages/WoshDetail/WoshDetailMain';
import WoshUsageStats from '@/components/wosh/WoshUsageStats';
import WoshAlert from '@/pages/WoshDetail/WoshAlert';
import useWoshDetailQuery, { WoshDetailReq } from '@/hooks/useWoshDetailQuery';
import WoshEventList from '@/pages/WoshDetail/WoshEventList';

const WoshDetail: React.FC = () => {
  const { woshId } = useParams();
  const request: WoshDetailReq = {
    expand: 'group,wosh_statuses,wosh_statuses.wosh_error',
  };
  const woshDetailQuery = useWoshDetailQuery(Number(woshId), request);
  const wosh = woshDetailQuery.data;

  if (woshDetailQuery.isError) {
    return <>error</>;
  }

  if (!wosh) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>製品詳細</title>
      </Helmet>
      <WoshHeader wosh={wosh} />
      <ContentWrapper>
        <WoshDetailMain wosh={wosh} woshDetailApi={woshDetailQuery} />
        <WoshUsageStats wosh={wosh} />
        <WoshAlert wosh={wosh} />
        <WoshEventList wosh={wosh} />
        {/* MEMO: 仕様が定まっていないためコメントアウト */}
        {/* <WoshNotificationList wosh={wosh} /> */}
      </ContentWrapper>
    </>
  );
};

export default WoshDetail;
