import { FC, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router';

export const TrackingView: FC = ({ children }) => {
  const listen = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const unlisten = listen((location: any) => {
      if (!window.gtag) return;
      window.gtag('config', 'G-2WDBX8EL02', { page_path: location.pathname });
    });
    return unlisten;
  }, [listen]);
  return children as ReactElement;
};
