import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type PasswordResetRes = {
  user_id: number;
};

const usePasswordResetQuery = (uidb64: string, token: string) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['passwordReset'],
    async () => {
      return (
        await axiosInstance()
          .get<PasswordResetRes>(`auth/reset/${uidb64}/${token}`)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    { retry: 0 }
  );
};

export default usePasswordResetQuery;
