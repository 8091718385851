import { Button, Form, Input, Modal, notification } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useCompanyCreateMutation, {
  CompanyCreateReq,
} from '@/hooks/useCompanyCreateMutation';
import {
  formItemValidateStatus,
  formItemValidateMessage,
  formError,
} from '@/utils/form';

type CompanyAddModalProps = {
  visible: boolean;
  onFinish: () => void;
  onCancel: () => void;
};

const CompanyAddModal: React.FC<CompanyAddModalProps> = ({
  visible,
  onFinish,
  onCancel,
}) => {
  const [companyCreateForm] = useForm();
  const companyCreateMutation = useCompanyCreateMutation();
  const handleCompanyCreate = (values: any) => {
    const req: CompanyCreateReq = {
      name: values.name,
    };
    companyCreateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '作成しました',
        });
        onFinish();
        companyCreateForm.resetFields();
      },
      onError: formError,
    });
  };

  return (
    <Modal footer={null} title="組織追加" visible={visible} onCancel={onCancel}>
      <Form onFinish={handleCompanyCreate} form={companyCreateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="組織名"
            name="name"
            validateStatus={formItemValidateStatus(
              companyCreateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(companyCreateMutation.error, 'name')}
          >
            <Input size="large" placeholder="組織名を入力してください" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              追加する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CompanyAddModal;
