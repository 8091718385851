import { useQuery } from 'react-query';
import axios from 'axios';
import { ListReq, ListRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';
import { Wosh } from '@/utils/wosh';
import { WoshEvent } from '@/utils/wosh_event';

export type WoshEventListReqQueryParam = ListReq & {
  wosh?: Wosh['id'];
};

export type WoshEventListRes = ListRes & {
  results: WoshEvent[];
};

export const getWoshEventList = async (
  reqQueryParam: WoshEventListReqQueryParam
) => {
  const axiosResponse = await axiosInstance().get<WoshEventListRes>(
    '/wosh_event',
    {
      params: reqQueryParam,
    }
  );

  return axiosResponse.data;
};

export const useWoshEventListQuery = (req: WoshEventListReqQueryParam) => {
  const axiosErrorHandler = useAxiosErrorHandler();

  return useQuery(['woshEventList', req], async () => {
    try {
      return await getWoshEventList(req);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosErrorHandler(error);
        throw error;
      }

      throw error;
    }
  });
};
