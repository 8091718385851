import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Result,
  Row,
  Table,
  Space,
  Select,
  Popconfirm,
  notification,
} from 'antd';
import { css } from '@emotion/css';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined } from '@ant-design/icons';
import ContentHeader from '@/components/common/ContentHeader';
import ContentWrapper from '@/components/common/ContentWrapper';
import ContentBlock from '@/components/common/ContentBlock';
import useUserListQuery, { UserListReq } from '@/hooks/useUserListQuery';
import { User } from '@/utils/user';
import UserAdminRole from '@/components/user/UserAdminRole';
import AdminEditModal from '@/pages/AdminList/AdminEditModal';
import AdminAddModal from '@/pages/AdminList/AdminAddModal';
import useAuth from '@/hooks/useAuth';
import { formError } from '@/utils/form';
import userUserDeleteMutation from '@/hooks/userUserDeleteMutation';

const productListContentStyle = css`
  padding: 0;
  margin-top: 50px;
`;

const headerRightStyle = css`
  padding: 0 44px;
`;

export type UserListFormValues = {
  search: string;
  id__exact: number;
  ordering: string;
  page: number;
  page_size: number;
};

const UserList: React.FC = () => {
  const auth = useAuth();

  // adminAddModal
  const [adminAddModalVisible, setAdminAddModalVisible] =
    useState<boolean>(false);

  // adminEditModal
  const [adminEditModalUser, setAdminEditModalUser] = useState<User | null>(
    null
  );

  // users
  const [userListForm] = useForm<UserListFormValues>();
  const [userListReq, setUserListReq] = useState<UserListReq>({
    ordering: '-id',
    page: 1,
    page_size: 100,
    is_admin: true,
    expand: '',
  });
  const userListQuery = useUserListQuery(userListReq);
  const users = userListQuery.data?.results;
  const handleUserList = async () => {
    const values = userListForm.getFieldsValue(true);
    await setUserListReq({
      ...userListReq,
      id__exact: values.id__exact,
      search: values.search,
      ordering: values.ordering,
      page: values.page,
      page_size: values.page_size,
    });
  };
  const handleUserFormClear = () => {
    userListForm.resetFields();
    userListForm.submit();
  };

  // userDelete
  const userDeleteMutation = userUserDeleteMutation();
  const handleUserDelete = (id: number) => {
    userDeleteMutation.mutate(id, {
      onSuccess: async () => {
        notification.success({
          message: '削除しました。',
        });
        await userListQuery.refetch();
      },
      onError: formError,
    });
  };

  return (
    <>
      <Helmet>
        <title>Admin一覧</title>
      </Helmet>
      <ContentWrapper style={productListContentStyle}>
        <ContentBlock>
          <ContentHeader title="Admin一覧">
            <div className={headerRightStyle}>
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => {
                  setAdminAddModalVisible(true);
                }}
                disabled={!auth.user?.globalAbilities?.can_admin_user_create}
              >
                Admin追加
              </Button>
            </div>
          </ContentHeader>
          <Collapse>
            <Collapse.Panel key="1" header="検索">
              <Form
                layout="vertical"
                onFinish={handleUserList}
                form={userListForm}
              >
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="検索" name="search">
                      <Input placeholder="名前、メールアドレス、電話番号" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="ID" name="id">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={8}>
                    <Form.Item label="並び順" name="ordering" initialValue="id">
                      <Select showSearch optionFilterProp="children">
                        <Select.Option value="id">ID : 昇順</Select.Option>
                        <Select.Option value="-id">ID : 降順</Select.Option>
                        <Select.Option value="name">名前 : 昇順</Select.Option>
                        <Select.Option value="-name">名前 : 降順</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={userListQuery.isLoading}
                  >
                    検索
                  </Button>
                  <Button htmlType="button" onClick={handleUserFormClear}>
                    リセット
                  </Button>
                </Space>
              </Form>
            </Collapse.Panel>
          </Collapse>
          {userListQuery.isError && (
            <Result status="warning" title="データの読み込みに失敗しました" />
          )}
          {userListQuery.isSuccess && (
            <Table
              rowKey="id"
              dataSource={users}
              scroll={{ x: 900 }}
              loading={userListQuery.isLoading}
              pagination={{
                position: ['topRight', 'bottomRight'],
                current: userListReq.page,
                total: userListQuery.data?.count,
                pageSize: userListReq.page_size,
                showTotal: (total: number, range: number[]) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: async (page, page_size) => {
                  userListForm.setFieldsValue({
                    page: page_size === userListReq.page_size ? page : 1,
                    page_size,
                  });
                  await handleUserList();
                },
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '500'],
              }}
            >
              <Table.Column title="ID" dataIndex="id" key="id" />
              <Table.Column title="名前" dataIndex="name" key="name" />
              <Table.Column
                title="権限"
                dataIndex="admin_role"
                key="role"
                render={(_, user: User) => <UserAdminRole user={user} />}
              />
              <Table.Column
                title="メールアドレス"
                dataIndex="email"
                key="email"
              />
              <Table.Column
                title="電話番号"
                dataIndex="phone_number"
                key="phone_number"
              />
              <Table.Column
                title=""
                key="edit"
                render={(_, user: User & { visible: boolean }) => (
                  <Space>
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      onClick={() => {
                        setAdminEditModalUser(user);
                      }}
                      disabled={!user?.abilities?.can_admin_user_update}
                    >
                      EDIT
                    </Button>
                    <AdminEditModal
                      user={user}
                      visible={adminEditModalUser === user}
                      onFinish={async () => {
                        await userListQuery.refetch();
                        setAdminEditModalUser(null);
                      }}
                      onCancel={() => setAdminEditModalUser(null)}
                    />
                    {user?.abilities?.can_user_delete && (
                      <Popconfirm
                        title={`${user.name}を削除しますか？`}
                        okText="削除する"
                        cancelText="キャンセル"
                        onConfirm={() => handleUserDelete(user.id)}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                  </Space>
                )}
              />
            </Table>
          )}
        </ContentBlock>
      </ContentWrapper>
      <AdminAddModal
        visible={adminAddModalVisible}
        onFinish={async () => {
          setAdminAddModalVisible(false);
          await userListQuery.refetch();
        }}
        onCancel={() => setAdminAddModalVisible(false)}
      />
    </>
  );
};

export default UserList;
