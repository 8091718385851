import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

type WoshUpdateCPUSerialPathParam = {
  id: number;
};

type WoshUpdateCPUSerialReqBody = {
  device_id: string;
};

type WoshUpdateCPUSerialRespBody = {
  device_id: string;
};

export type WoshUpdateCPUSerialMutationVariables = {
  woshUpdateCPUSerialPathParam: WoshUpdateCPUSerialPathParam;
  woshUpdateCPUSerialReqBody: WoshUpdateCPUSerialReqBody;
};

const updateWoshCPUSerial = async (
  woshUpdateCPUSerialPathParam: WoshUpdateCPUSerialPathParam,
  woshUpdateCPUSerialReqBody: WoshUpdateCPUSerialReqBody
) => {
  const axiosResponse = await axiosInstance().post<WoshUpdateCPUSerialRespBody>(
    `/wosh/update_cpu_serial/${woshUpdateCPUSerialPathParam.id}`,
    woshUpdateCPUSerialReqBody
  );

  return axiosResponse.data;
};

const useWoshUpdateCPUSerialMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();

  return useMutation<
    WoshUpdateCPUSerialRespBody,
    AxiosError<ErrorRes>,
    WoshUpdateCPUSerialMutationVariables
  >(async (mutationVariables) => {
    const { woshUpdateCPUSerialPathParam, woshUpdateCPUSerialReqBody } =
      mutationVariables;

    try {
      return await updateWoshCPUSerial(
        woshUpdateCPUSerialPathParam,
        woshUpdateCPUSerialReqBody
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        axiosErrorHandler(error);
        throw error;
      }

      throw error;
    }
  });
};

export default useWoshUpdateCPUSerialMutation;
