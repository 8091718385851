import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { ListReq, ListRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshSummaryGraphReq = ListReq & {
  id?: number;
  wosh?: number;
  start: string | null;
  end: string | null;
};

export type WoshSummaryGraphRes = ListRes & {
  results: any;
  hand_wosh_count_month_total: number;
  hand_wosh_count_day_avg: number;
  smartphone_sanitize_count_month_total: number;
  smartphone_sanitize_count_day_avg: number;
};

const useWoshSummaryGraphQuery = (req: WoshSummaryGraphReq) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['woshSummaryGraph', req],
    async () => {
      return (
        await axiosInstance()
          .get<WoshSummaryGraphRes>('/wosh_summary_graph', {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    {}
  );
};

export default useWoshSummaryGraphQuery;
