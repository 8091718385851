import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type UserPasswordUpdateReq = {
  id: number;
  new_password: string;
};

export type UserPasswordUpdateRes = null;

const useUserPasswordUpdateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, UserPasswordUpdateReq>(
    async (req: UserPasswordUpdateReq) => {
      const { id, ...rest } = req;
      return (
        await axiosInstance()
          .post<UserPasswordUpdateRes>(`/user/change_password/${id}`, rest)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useUserPasswordUpdateMutation;
