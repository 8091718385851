import React from 'react';
import { Tag } from 'antd';
import { User, UserAdminRoles } from '@/utils/user';

type UserAdminRoleProps = {
  user: User;
};
const UserAdminRole: React.FC<UserAdminRoleProps> = ({ user }) => {
  if (user.admin_role === UserAdminRoles.manager) {
    return <Tag>admin</Tag>;
  }
  if (user.admin_role === UserAdminRoles.operator) {
    return <Tag>オペレーター</Tag>;
  }
  if (user.admin_role === UserAdminRoles.call_center) {
    return <Tag>コールセンター</Tag>;
  }
  if (user.admin_role === UserAdminRoles.factory) {
    return <Tag>工場</Tag>;
  }
  return null;
};

export default UserAdminRole;
