import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { User } from '@/utils/user';
import { ListReq, ListRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type UserListReq = ListReq & {
  id__exact?: number;
  is_admin?: boolean;
  is_company?: boolean;
  company_id__exact?: number;
  group_ids?: number[];
  search?: string;
  expand?: string;
  page_size?: number;
  ordering?: string;
};

export type UserListRes = ListRes & {
  results: User[];
};

const useUserListQuery = (req: UserListReq, options?: any) => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useQuery(
    ['userList', req],
    async () => {
      return (
        await axiosInstance()
          .get<UserListRes>('/user', {
            params: req,
          })
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    },
    options
  );
};

export default useUserListQuery;
