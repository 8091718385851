import React, { FC, useEffect, useRef } from 'react';
import { css } from '@emotion/css';
import { useLocation } from 'react-router';
import { Tag } from 'antd';
import { WoshStatus } from '@/utils/wosh_status';
import {
  StatusErrorIcon,
  StatusMaintenanceIcon,
} from '@/components/common/Icon';
import { Wosh, WoshStatusLevels } from '@/utils/wosh';
import ContentBlock from '@/components/common/ContentBlock';
import ContentHeader from '@/components/common/ContentHeader';

type WoshAlertProps = {
  // eslint-disable-next-line react/require-default-props
  wosh?: Wosh;
};

const woshDetailContentStyle = {
  padding: 0,
  marginTop: '50px',
};

const woshDetailContentFooter = css`
  padding: 30px;
  display: flex;
  overflow-x: auto;
  width: 100%;
`;

const alertTagStyle = css`
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-right: 10px;
  white-space: nowrap;
`;

const WoshAlert: FC<WoshAlertProps> = ({ wosh }) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  useEffect(() => {
    if (location.hash === '#error_statuses') {
      setTimeout(() => {
        ref.current?.scrollIntoView();
      }, 1000);
    }
  }, []);

  if (!wosh) {
    return null;
  }

  return (
    <ContentBlock id="error_statuses" style={woshDetailContentStyle}>
      <ContentHeader
        title="現在発生中のエラー"
        subTitle="※エラーアイコンをクリックすると対処マニュアルが確認できます"
      />
      <div className={woshDetailContentFooter} ref={ref}>
        {wosh.wosh_statuses?.map((wosh_status: WoshStatus) => {
          let config: {
            color: string;
          };
          switch (wosh_status.wosh_error?.error_level) {
            case WoshStatusLevels.normal:
              config = {
                color: '#39d94c',
              };
              break;
            case WoshStatusLevels.error:
              config = {
                color: '#e35c5c',
              };
              break;
            case WoshStatusLevels.prepare:
              config = {
                color: '#ffa500',
              };
              break;
            case WoshStatusLevels.urgent:
              config = {
                color: '#e35c5c',
              };
              break;
            default:
              config = {
                color: '#ffffff',
              };
          }
          if (wosh_status.wosh_error.code?.startsWith('H')) {
            return (
              <a
                key={wosh_status.id}
                href={wosh_status.wosh_error.support_link}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: 'inherit',
                }}
              >
                <Tag
                  className={alertTagStyle}
                  icon={
                    <StatusErrorIcon
                      className="mr-2"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  }
                  color={config.color}
                >
                  {wosh_status.wosh_error.name}
                </Tag>
              </a>
            );
          }
          return (
            <a
              key={wosh_status.id}
              href={wosh_status.wosh_error.support_link}
              target="_blank"
              rel="noreferrer"
              style={{
                color: 'inherit',
              }}
            >
              <Tag
                className={alertTagStyle}
                icon={
                  <StatusMaintenanceIcon
                    className="mr-2"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                }
                color={config.color}
              >
                {wosh_status.wosh_error.name}
              </Tag>
            </a>
          );
        })}
        {wosh.wosh_statuses.length > 0 ? null : (
          <span>現在発生しているエラーはありません</span>
        )}
      </div>
    </ContentBlock>
  );
};

export default WoshAlert;
