import React from 'react';
import { Tag } from 'antd';
import { css } from '@emotion/css';
import { ActiveOnIcon, ActiveOffIcon } from '@/components/common/Icon';
import { Wosh } from '@/utils/wosh';

const onStyle = css`
  background-color: transparent;
  border: 1px solid #39d94c;
  color: #39d94c;
`;

const offStyle = css`
  background-color: transparent;
  border: 1px solid #acacac;
  color: #acacac;
`;

type WoshActiveTagProps = {
  wosh: Wosh;
};

type WoshActiveTagComponent = (props: WoshActiveTagProps) => JSX.Element;

const WoshActiveTag: WoshActiveTagComponent = ({
  wosh,
}: WoshActiveTagProps) => (
  <Tag
    icon={wosh.active ? <ActiveOnIcon /> : <ActiveOffIcon />}
    className={wosh.active ? onStyle : offStyle}
  >
    {wosh.active ? 'オンライン' : 'オフライン'}
  </Tag>
);

export default WoshActiveTag;
