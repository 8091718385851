import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type WoshCsvExportReq = Record<string, never>;

export type WoshCsvExportRes = string;

const useWoshCsvExportMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<WoshCsvExportRes, AxiosError<ErrorRes>, WoshCsvExportReq>(
    async (req: WoshCsvExportReq): Promise<WoshCsvExportRes> => {
      const ret = (
        await axiosInstance()
          .get<WoshCsvExportRes>('/wosh/export', req)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
      // save ret to local file by showing save dialog
      const blob = new Blob([ret], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'export.csv';
      a.click();
      return ret;
    }
  );
};

export default useWoshCsvExportMutation;
