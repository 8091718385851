import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type AdminUpdateReq = {
  id: number;
  email: string;
  name: string;
  phone_number: string;
  admin_role: number;
};

export type AdminUpdateRes = null;

const useAdminUpdateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<null, AxiosError<ErrorRes>, AdminUpdateReq>(
    async (req: AdminUpdateReq) => {
      const { id, ...rest } = req;
      return (
        await axiosInstance()
          .post<AdminUpdateRes>(`/admin_user/update/${id}`, rest)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useAdminUpdateMutation;
