import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';
import { WoshSummaryGraphReq } from './useWoshSummaryGraphQuery';

export type WoshSummaryExportReq = WoshSummaryGraphReq;

export type WoshSummaryExportRes = string;

const useWoshSummaryExportMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<
    WoshSummaryExportRes,
    AxiosError<ErrorRes>,
    WoshSummaryExportReq
  >(async (req: WoshSummaryExportReq): Promise<WoshSummaryExportRes> => {
    const ret = (
      await axiosInstance()
        .get<WoshSummaryExportRes>('/wosh_summary_export', {
          params: req,
        })
        .catch((error: AxiosError) => {
          axiosErrorHandler(error);
          throw error;
        })
    ).data;
    // save ret to local file by showing save dialog
    const blob = new Blob([ret], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'summary.csv';
    a.click();
    return ret;
  });
};

export default useWoshSummaryExportMutation;
