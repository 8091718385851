import React from 'react';
import { Anchor } from 'antd';
import { css } from '@emotion/css';
import { Wosh } from '@/utils/wosh';

// page header
const pageHeaderStyle = css`
  border-bottom: 1px solid #e8e8e8;
  height: 126px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
`;

const pageHeaderInnerStyle = css`
  display: flex;
  height: 126px;
  justify-content: space-between;
  align-items: center;
  min-width: 960px;
`;

const pageHeaderLeftStyle = css`
  padding: 50px 30px;
  font-size: 16px;
  font-weight: bold;
`;

const serialNumberStyle = css`
  color: #acacac;
  font-weight: normal;
  font-size: 9px;
  margin-left: 10px;
`;

const pageHeaderRightStyle = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const pageHeaderNavList = css`
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 18px;
    background-color: #e8e8e8;
  },
`;

// const pageHeaderNavListItem = css`
//   margin: 0 20px;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   color: rgba(0, 0, 0, 0.85);
//   position: relative;
//   &:hover {
//     &::before {
//       content: '';
//       position: absolute;
//       left: 0;
//       bottom: 0;
//       width: 100%;
//       height: 2px;
//       background-color: #2cbbdb;
//     }
//   }
// `;

const badgeStyle = css`
  position: absolute;
  top: -13px;
  right: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #e35c5c;
  color: #fff;
  font-size: 6px;
`;

const ancherWithaBadge = (wosh: Wosh) => (
  <span className="relative">
    {wosh.wosh_statuses.length > 0 && (
      <span className={badgeStyle}>{wosh.wosh_statuses?.length}</span>
    )}
    現在発生中のエラー
  </span>
);

type WoshHeaderProps = {
  wosh: Wosh;
};

type WoshHeaderComponent = (props: WoshHeaderProps) => JSX.Element;

const WoshHeader: WoshHeaderComponent = ({ wosh }: WoshHeaderProps) => {
  return (
    <div className={pageHeaderStyle}>
      <div className={pageHeaderInnerStyle}>
        <div className={pageHeaderLeftStyle}>
          {wosh.place}
          <span className={serialNumberStyle}>{wosh.serial_number}</span>
        </div>
        <div className={pageHeaderRightStyle}>
          <Anchor affix={false} className="header-anchor-link">
            <Anchor.Link href="#wosh" title="製品登録情報" />
            <Anchor.Link href="#status" title="利用状況" />
            <Anchor.Link
              href="#error_statuses"
              title={ancherWithaBadge(wosh)}
            />
          </Anchor>
          <div className={pageHeaderNavList}>
            {/* <Link className={pageHeaderNavListItem} to="/"> */}
            {/*  通知一覧 */}
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WoshHeader;
