import React, { useState } from 'react';
import { Button, Form, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ModalCtrl } from '@/hooks/useModalCtrl';
import { Wosh } from '@/utils/wosh';
import useWoshUpdateGroupMutation, {
  WoshUpdateGroupReq,
} from '@/hooks/useWoshUpdateGroupMutation';
import {
  formError,
  formItemValidateMessage,
  formItemValidateStatus,
} from '@/utils/form';
import useGroupListQuery, { GroupListReq } from '@/hooks/useGroupListQuery';
import { userIsAdmin } from '@/utils/user';
import useAuth from '@/hooks/useAuth';
import useCompanyListQuery, {
  CompanyListReq,
} from '@/hooks/useCompanyListQuery';
import { Company } from '@/utils/company';

type WoshUpdateGroupModalProps = {
  wosh: Wosh;
  woshDetailApi: any;
  modalCtrl: ModalCtrl;
};

const WoshUpdateGroupModal: React.FC<WoshUpdateGroupModalProps> = ({
  wosh,
  woshDetailApi,
  modalCtrl,
}) => {
  const WoshUpdateGroupMutation = useWoshUpdateGroupMutation();
  const handleWoshUpdateGroup = (values: any) => {
    const req: WoshUpdateGroupReq = {
      id: wosh.id,
      group: values.group,
    };
    WoshUpdateGroupMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        modalCtrl.hide();
        woshDetailApi.refetch();
      },
      onError: formError,
    });
  };

  const [woshGroupUpdateForm] = useForm();
  const auth = useAuth();
  let companies: Company[] | undefined = [];
  if (userIsAdmin(auth.user)) {
    // company
    const companyListOption: CompanyListReq = {
      ordering: 'id',
      page_size: 9999999,
    };
    const companyListQuery = useCompanyListQuery(companyListOption);
    companies = companyListQuery.data?.results;
  }

  // groups
  const [groupListReq, setGroupListReq] = useState<GroupListReq>({
    // ページネーションがかからないように大きい数字を設定する
    page_size: 9999999,
    company_id__exact: wosh.group.company_id,
  });
  const groupListQuery = useGroupListQuery(groupListReq);
  const groups = groupListQuery.data?.results;

  const changeCompanyId = async (value: any) => {
    await setGroupListReq({
      // ページネーションがかからないように大きい数字を設定する
      page_size: 9999999,
      company_id__exact: value,
    });
    await groupListQuery.refetch();
    woshGroupUpdateForm.setFieldsValue({
      group: null,
    });
  };

  return (
    <Modal
      title="グループ"
      footer={null}
      visible={modalCtrl.visible}
      onCancel={modalCtrl.hide}
    >
      <Form onFinish={handleWoshUpdateGroup} form={woshGroupUpdateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          {userIsAdmin(auth.user) && (
            <Form.Item
              label="組織"
              name="company"
              initialValue={wosh.group.company_id}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={changeCompanyId}
              >
                {companies?.map((company) => {
                  return (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="グループ"
            name="group"
            initialValue={wosh.group.id}
            validateStatus={formItemValidateStatus(
              WoshUpdateGroupMutation.error,
              'group'
            )}
            help={formItemValidateMessage(
              WoshUpdateGroupMutation.error,
              'group'
            )}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {groups?.map((group) => {
                return (
                  <Select.Option key={group.id} value={group.id}>
                    {group.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={modalCtrl.hide}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default WoshUpdateGroupModal;
