import axios from 'axios';

export function axiosInstance() {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
  client.interceptors.request.use((config) => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      if (config && config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `JWT ${access_token}`;
      }
    }
    return config;
  });
  // client.interceptors.response.use(
  //   (response) => response,
  //   (error: AxiosError) => {
  //     if (error.response?.status === 401) {
  //       alert(401);
  //     }
  //   }
  // );
  return client;
}
