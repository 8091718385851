import { AxiosError } from 'axios';
import { notification } from 'antd';
import useAuth from '@/hooks/useAuth';

const useAxiosErrorHandler = () => {
  const auth = useAuth();
  return (error: AxiosError) => {
    if (error) {
      const status = error.response?.status;
      if (status) {
        if (status === 401) {
          auth.logout();
          return;
        }
        // TODO validationエラーの場合のみ除外したいが、難しいので4xxはコメントアウトした。
        // if (status >= 400 && status <= 499) {
        //   notification.error({
        //     message: `エラーが発生しました : ${status}`,
        //   });
        // }
        if (status >= 500 && status <= 599) {
          notification.error({
            message: `エラーが発生しました : ${status}`,
          });
        }
      }
    }
  };
};

export default useAxiosErrorHandler;
