import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { CreatedRes, ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type AdminCreateReq = {
  company: number;
  email: string;
  name: string;
  password: string;
  phone_number: string;
  admin_role: number;
};

export type AdminCreateRes = CreatedRes;

const useAdminCreateMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<AdminCreateRes, AxiosError<ErrorRes>, AdminCreateReq>(
    async (req: AdminCreateReq): Promise<AdminCreateRes> => {
      return (
        await axiosInstance()
          .post<AdminCreateRes>('/admin_user/create', req)
          .catch((error: AxiosError) => {
            axiosErrorHandler(error);
            throw error;
          })
      ).data;
    }
  );
};

export default useAdminCreateMutation;
