import React, { useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Chart,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip as ChartJSTooltip,
  TooltipModel,
} from 'chart.js';
import dayjs from 'dayjs';
import {
  Col,
  Row,
  Spin,
  Tooltip,
  DatePicker,
  Space,
  ConfigProvider,
  Button,
  notification,
} from 'antd';
import jaLocale from 'antd/lib/locale/ja_JP'; // Locale
import jaPickerLocale from 'antd/lib/date-picker/locale/ja_JP';
import moment from 'moment';
import { ReactComponent as IconWosh } from '@/assets/img/icon_wash.svg';
import { ReactComponent as IconSmartPhone } from '@/assets/img/icon_smart_phone.svg';
import useWoshSummaryGraphQuery, {
  WoshSummaryGraphReq,
} from '@/hooks/useWoshSummaryGraphQuery';
import { Wosh } from '@/utils/wosh';
import useAuth from '@/hooks/useAuth';
import useWoshSummaryExportMutation, {
  WoshSummaryExportReq,
} from '@/hooks/useWoshSummaryExportMutation';

const { RangePicker } = DatePicker;

const graphWrapper = css`
  border: 2px solid #f4f4f4;
  border-radius: 4px;
  padding: 0 48px 27px;
`;

const graphContentHeader = css`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
`;

const graphHeader = css`
  margin-bottom: 20px;
`;

const graphHeaderInner = css`
  border: 2px solid #f4f4f4;
  border-radius: 4px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48.5%;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 0;
    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartJSTooltip
);

type GraphProps = {
  // eslint-disable-next-line react/require-default-props
  wosh?: Wosh;
};

type GraphComponent = (props: GraphProps) => JSX.Element;

type RangeValueType = null | [moment.Moment | null, moment.Moment | null];

dayjs.locale('ja-JP');

const Graph: GraphComponent = ({ wosh }: GraphProps) => {
  // const { Option } = Select;
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipLabel, setToolTipLabel] = useState('');
  const [toolTipNum, setToolTipNum] = useState('');
  const [toolTipTitle, setToolTipTitle] = useState('');
  const [dateRange, setDateRange] = useState<RangeValueType>([
    moment(dayjs().subtract(1, 'month').toISOString()),
    moment(dayjs().toISOString()),
  ]);

  const showTooltip = (args: {
    chart: Chart;
    tooltip: TooltipModel<'line'>;
  }) => {
    const { tooltip } = args;

    if (!tooltip) return;

    setShowToolTip(tooltip.opacity !== 0);

    if (tooltip.opacity === 0) return;

    const str = tooltip.body[0].lines[0].split(':');
    setToolTipLabel(tooltip.title[0]);
    setToolTipNum(str[1]);
    setToolTipTitle(str[0]);
    setPositionX(tooltip.caretX);
    setPositionY(tooltip.caretY);
  };

  const tooltipInner = (
    <div>
      {toolTipTitle}:
      <br />
      <span className="text-2xl font-bold mr-2">{toolTipNum}</span>回 /
      {toolTipLabel}
    </div>
  );

  const [woshSummaryGraphReq, setWoshSummaryGraphReq] =
    useState<WoshSummaryGraphReq>({
      wosh: wosh?.id,
      start: moment().subtract(1, 'month').toISOString(),
      end: moment().toISOString(),
    });

  const woshSummaryGraphQuery = useWoshSummaryGraphQuery(woshSummaryGraphReq);

  const {
    results,
    hand_wosh_count_month_total,
    hand_wosh_count_day_avg,
    smartphone_sanitize_count_month_total,
    smartphone_sanitize_count_day_avg,
  } = woshSummaryGraphQuery.data || {};

  const handlePeriodChange = async (dates: RangeValueType) => {
    // default end is today
    const end: moment.Moment = dates && dates[1] ? dates[1] : moment();
    // default start is 1 month ago from end
    const start: moment.Moment =
      dates && dates[0] ? dates[0] : end.clone().subtract(1, 'month'); // Moment is sadly mutable. Cloning needed.
    await setWoshSummaryGraphReq({
      ...woshSummaryGraphReq,
      start: start.startOf('day').toISOString(),
      end: end.endOf('day').toISOString(),
    });
    await woshSummaryGraphQuery.refetch();
  };

  const stepSize = useMemo(() => {
    if (results == null) {
      return 1;
    }
    const maxData = Math.max(
      results.datasets.map((dataset: any) => Math.max(dataset.data))
    );
    return Math.log10(maxData);
  }, [results]);

  const options: ChartOptions<'line'> = {
    scales: {
      y: {
        min: 0,
        suggestedMax: 10,
        ticks: {
          stepSize,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: showTooltip,
      },
    },
    responsive: true,
  };

  const auth = useAuth();

  const woshSummaryExportMutation = useWoshSummaryExportMutation();
  const handleWoshSummaryExport = () => {
    const req: WoshSummaryExportReq = woshSummaryGraphReq;
    woshSummaryExportMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: 'CSVをダウンロードしました',
        });
      },
      onError: () =>
        notification.error({
          message: 'CSV出力失敗しました',
        }),
    });
  };
  return (
    <>
      {results ? (
        <>
          <div className={graphHeader}>
            <Row justify="space-between">
              <Col className={graphHeaderInner}>
                <IconWosh />
                <div className="ml-7">
                  手洗い回数：
                  <br />
                  <span className="text-xs inline-block mt-2">
                    <span className="text-2xl font-bold mr-2">
                      {hand_wosh_count_month_total}
                    </span>
                    回 / 月{' '}
                    <span className="text-2xl font-bold mr-2 ml-5">
                      {hand_wosh_count_day_avg}
                    </span>
                    回 / 日
                  </span>
                </div>
              </Col>
              <Col className={graphHeaderInner}>
                <IconSmartPhone />
                <div className="ml-7">
                  スマホ殺菌回数：
                  <br />
                  <span className="text-xs inline-block mt-2">
                    <span className="text-2xl font-bold mr-2">
                      {smartphone_sanitize_count_month_total}
                    </span>
                    回 / 月{' '}
                    <span className="text-2xl font-bold mr-2 ml-5">
                      {smartphone_sanitize_count_day_avg}
                    </span>
                    回 / 日
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className={graphWrapper}>
            <div className={graphContentHeader}>
              <Space direction="horizontal" size={12}>
                <ConfigProvider locale={jaLocale}>
                  <RangePicker
                    value={dateRange}
                    onChange={(
                      dates: RangeValueType,
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      dateStrings: [string, string]
                    ) => {
                      setDateRange(dates);
                      handlePeriodChange(dates);
                    }}
                    locale={{
                      ...jaPickerLocale,
                      lang: {
                        ...jaPickerLocale.lang,
                        previousMonth: '前月',
                        nextMonth: '次月',
                        today: '今日',
                        shortWeekDays: [
                          '日',
                          '月',
                          '火',
                          '水',
                          '木',
                          '金',
                          '土',
                        ],
                        shortMonths: [
                          '1月',
                          '2月',
                          '3月',
                          '4月',
                          '5月',
                          '6月',
                          '7月',
                          '8月',
                          '9月',
                          '10月',
                          '11月',
                          '12月',
                        ],
                      },
                    }}
                  />
                </ConfigProvider>
                <div>
                  {auth.user?.globalAbilities?.can_wosh_export && (
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      onClick={handleWoshSummaryExport}
                    >
                      CSV出力
                    </Button>
                  )}
                </div>
              </Space>
            </div>
            <div className="relative">
              <Tooltip
                title={tooltipInner}
                visible={showToolTip}
                overlayClassName="graph-tooltip"
              >
                <span
                  style={{
                    position: 'absolute',
                    left: positionX,
                    top: positionY - 10,
                  }}
                />
              </Tooltip>
              <Line data={results} width={100} height={50} options={options} />
            </div>
          </div>
        </>
      ) : (
        <Spin size="large" />
      )}{' '}
    </>
  );
};

export default Graph;
