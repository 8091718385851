import { Button, Form, Input, Modal, notification, Select } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useGroupCreateMutation, {
  GroupCreateReq,
} from '@/hooks/useGroupCreateMutation';
import { Company } from '@/utils/company';
import {
  formItemValidateStatus,
  formItemValidateMessage,
  formError,
} from '@/utils/form';
import { userIsAdmin } from '@/utils/user';
import useAuth from '@/hooks/useAuth';

type GroupAddModalProps = {
  visible: boolean;
  companies: Company[];
  onFinish: () => void;
  onCancel: () => void;
};

const GroupAddModal: React.FC<GroupAddModalProps> = ({
  visible,
  companies,
  onFinish,
  onCancel,
}) => {
  const auth = useAuth();
  const [groupCreateForm] = useForm();
  const groupCreateMutation = useGroupCreateMutation();
  const handleGroupCreate = (values: any) => {
    const req: GroupCreateReq = {
      company: values.company_id || auth.user?.company_id,
      name: values.name,
      contract: values.contract,
    };
    groupCreateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '作成しました',
        });
        onFinish();
        groupCreateForm.resetFields();
      },
      onError: formError,
    });
  };

  return (
    <Modal
      footer={null}
      title="グループ追加"
      visible={visible}
      onCancel={onCancel}
    >
      <Form onFinish={handleGroupCreate} form={groupCreateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          {userIsAdmin(auth.user) && (
            <Form.Item
              label="組織"
              name="company_id"
              validateStatus={formItemValidateStatus(
                groupCreateMutation.error,
                'company'
              )}
              help={formItemValidateMessage(
                groupCreateMutation.error,
                'company'
              )}
            >
              <Select showSearch optionFilterProp="children">
                {companies?.map((tmp_company) => {
                  return (
                    <Select.Option key={tmp_company.id} value={tmp_company.id}>
                      {tmp_company.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="名称"
            name="name"
            validateStatus={formItemValidateStatus(
              groupCreateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(groupCreateMutation.error, 'name')}
          >
            <Input size="large" placeholder="名称を入力してください" />
          </Form.Item>
          <Form.Item
            label="連絡先"
            name="contract"
            validateStatus={formItemValidateStatus(
              groupCreateMutation.error,
              'contract'
            )}
            help={formItemValidateMessage(
              groupCreateMutation.error,
              'contract'
            )}
          >
            <Input size="large" placeholder="連絡先を入力してください" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              追加する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default GroupAddModal;
