import React, { memo, useEffect } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { css } from '@emotion/css';
import { useForm } from 'antd/es/form/Form';
import {
  formError,
  formItemValidateMessage,
  formItemValidateStatus,
} from '@/utils/form';
import { Company } from '@/utils/company';
import useCompanyUpdateMutation, {
  CompanyUpdateMutationVariables,
} from '@/hooks/useCompanyUpdateMutation';

const formInsideWrapper = css`
  max-width: 420px;
  margin: auto;
`;

const submitButton = css`
  margin-left: 1.25rem;
  min-width: 180px;
  font-weight: bold;
`;

type CompanyEditModalProps = {
  visible: boolean;
  company: Company | null;
  onFinish: () => void;
  onCancel: () => void;
};

type FormInputValues = {
  name: string;
};

const CompanyEditModal: React.FC<CompanyEditModalProps> = memo(
  ({ visible, company, onFinish, onCancel }) => {
    const [form] = useForm<FormInputValues>();
    const companyUpdateMutation = useCompanyUpdateMutation();
    const handleCompanyUpdate = async (formValues: FormInputValues) => {
      if (!company) return;

      const mutationVariables: CompanyUpdateMutationVariables = {
        companyUpdatePathParam: {
          id: company.id,
        },
        companyUpdateReqBody: {
          name: formValues.name,
        },
      };
      companyUpdateMutation.mutate(mutationVariables, {
        onSuccess: () => {
          notification.success({
            message: '更新しました',
          });
          onFinish();
        },
        onError: formError,
      });
    };

    const onCancelWrapper = async () => {
      companyUpdateMutation.reset();
      await form.validateFields();
      onCancel();
    };

    useEffect(() => {
      if (!visible) {
        return;
      }

      form.setFieldsValue({ name: company?.name });
    }, [company, visible]);

    return (
      <Modal
        footer={null}
        onCancel={onCancelWrapper}
        visible={visible}
        title="組織更新"
      >
        <Form form={form} onFinish={handleCompanyUpdate}>
          <div className={formInsideWrapper}>
            <Form.Item
              label="名称"
              name="name"
              validateStatus={formItemValidateStatus(
                companyUpdateMutation.error,
                'name'
              )}
              help={formItemValidateMessage(
                companyUpdateMutation.error,
                'name'
              )}
            >
              <Input size="large" placeholder="名称を入力してください" />
            </Form.Item>
            <div className="flex justify-center">
              <Button shape="round" size="large" onClick={onCancelWrapper}>
                キャンセル
              </Button>
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                className={submitButton}
              >
                更新する
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
);

export default CompanyEditModal;
