import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorRes } from '@/hooks/common';
import { axiosInstance } from '@/utils/axios';
import useAxiosErrorHandler from '@/hooks/useAxiosErrorHandler';

export type AuthPasswordChangeReq = {
  uidb64: string;
  token: string;
  password: string;
};

export type AuthPasswordChangeRes = {
  result: boolean;
};

const useAuthPasswordChangeMutation = () => {
  const axiosErrorHandler = useAxiosErrorHandler();
  return useMutation<
    AuthPasswordChangeRes,
    AxiosError<ErrorRes>,
    AuthPasswordChangeReq
  >(async (req: AuthPasswordChangeReq): Promise<AuthPasswordChangeRes> => {
    return (
      await axiosInstance()
        .post<AuthPasswordChangeRes>('/auth/password/change', req)
        .catch((error: AxiosError) => {
          axiosErrorHandler(error);
          throw error;
        })
    ).data;
  });
};

export default useAuthPasswordChangeMutation;
