import { Group } from '@/utils/group';
import { Company } from '@/utils/company';
import { WoshStatus } from '@/utils/wosh_status';

export type Wosh = {
  id: number;
  name: string;
  transmission: boolean;
  serial_number: string;
  device_id: string;
  group_name: string;
  place: string;
  group_id: number;
  group: Group;
  company_id: number;
  company: Company;
  status_level: WoshStatusLevel;
  memo_client_to_client: string;
  memo_operator_to_client: string;
  memo_operator_to_operator: string;
  created_at: string;
  active: boolean;
  wosh_statuses: WoshStatus[];
  abilities: {
    can_wosh_update: boolean;
    can_wosh_delete: boolean;
    can_wosh_group_id_update: boolean;
  };
};

export const WoshStatusLevels = {
  normal: 0,
  prepare: 1,
  urgent: 2,
  error: 3,
} as const;

export type WoshStatusLevel =
  typeof WoshStatusLevels[keyof typeof WoshStatusLevels];
