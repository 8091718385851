import React, { FC, useState } from 'react';
import { css } from '@emotion/css';
import { Button, Col, Image, List, Row } from 'antd';
import ContentBlock from '@/components/common/ContentBlock';
import ContentHeader from '@/components/common/ContentHeader';
import WoshActiveTag from '@/components/wosh/WoshActiveTag';
import WoshStatusTag from '@/components/wosh/WoshStatusTag';
import { Wosh } from '@/utils/wosh';
import useModalCtrl from '@/hooks/useModalCtrl';
import WoshUpdatePlaceModal from '@/pages/WoshDetail/WoshUpdatePlaceModal';
import WoshUpdateCommentModal from '@/pages/WoshDetail/WoshUpdateCommentModal';
import useAuth from '@/hooks/useAuth';
import { userIsAdmin } from '@/utils/user';
import WoshUpdateGroupModal from '@/pages/WoshDetail/WoshUpdateGroupModal';
import WoshImage from '@/assets/img/wosh.jpg';
import WoshUpdateCPUSerialModal from '@/pages/WoshDetail/WoshUpdateCPUSerialModal';

// wosh detail
const woshDetailContentStyle = {
  padding: 0,
  marginTop: '50px',
};

const woshDetailContentInnerStyle = css`
  padding: 40px;
`;

const contentHeaderRightStyle = css`
  font-size: 12px;
  padding: 26px 44px;
  border-left: 1px solid #e8e8e8;
`;

const detailPrefixStyle = css`
  position: absolute;
  right: 0;
  color: #c3c3c3;
  top: 50%;
  transform: translateY(-50%);
`;

const editButtonLayoutStyle = css`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

type WoshDetailMainProps = {
  wosh: Wosh;
  woshDetailApi: any;
};

const WoshDetailMain: FC<WoshDetailMainProps> = ({
  wosh,
  woshDetailApi,
}: WoshDetailMainProps) => {
  const auth = useAuth();
  const woshUpdatePlaceModalCtrl = useModalCtrl();
  const woshUpdateCommentModalCtrl = useModalCtrl();
  const woshUpdateGroupModalCtrl = useModalCtrl();
  const woshUpdateCPUSerialModalCtrl = useModalCtrl();
  const [title, setTitle] = useState('コメント');
  const [commentField, setCommentField] = useState('memo_client_to_client');
  const [commentValue, setCommentValue] = useState('');

  const showCommentModal = (
    modalTitle: string,
    fieldName: string,
    value: string
  ) => {
    setTitle(modalTitle);
    setCommentField(fieldName);
    setCommentValue(value);
    woshUpdateCommentModalCtrl.show();
  };

  if (!wosh) {
    return null;
  }

  if (!auth.user) {
    return null;
  }

  return (
    <>
      <ContentBlock id="wosh" style={woshDetailContentStyle}>
        <ContentHeader title="製品登録情報">
          <div className={contentHeaderRightStyle}>
            <span className="mr-3">通信状態 :</span>
            <WoshActiveTag wosh={wosh} />
          </div>
        </ContentHeader>
        <div className={woshDetailContentInnerStyle}>
          <Row justify="space-between">
            <Col span={24} lg={{ span: 10 }}>
              <Image
                width="100%"
                className="mr-10"
                src={WoshImage}
                preview={false}
              />
            </Col>
            <Col span={24} lg={{ span: 13 }}>
              <List
                className="pl-10"
                style={{ borderBottom: '1px solid #e8e8e8' }}
              >
                <List.Item>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={5} md={6} className="font-bold pr-2">
                      グループ
                      <span className={detailPrefixStyle}>:</span>
                    </Col>
                    <Col span={19} md={18} className="pl-7">
                      {wosh.group.name}
                      <div className={editButtonLayoutStyle}>
                        <Button
                          type="primary"
                          shape="round"
                          ghost
                          onClick={() => {
                            woshUpdateGroupModalCtrl.show();
                          }}
                          disabled={!wosh.abilities?.can_wosh_group_id_update}
                        >
                          EDIT
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={5} md={6} className="font-bold pr-2">
                      設置場所
                      <span className={detailPrefixStyle}>:</span>
                    </Col>
                    <Col span={19} md={18} className="pl-7">
                      {wosh.place}
                      <div className={editButtonLayoutStyle}>
                        <Button
                          type="primary"
                          shape="round"
                          ghost
                          onClick={() => {
                            woshUpdatePlaceModalCtrl.show();
                          }}
                          disabled={!wosh.abilities?.can_wosh_update}
                        >
                          EDIT
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={5} md={6} className="font-bold pr-2">
                      製造番号
                      <span className={detailPrefixStyle}>:</span>
                    </Col>
                    <Col span={19} md={18} className="pl-7">
                      {wosh.serial_number}
                    </Col>
                  </Row>
                </List.Item>
                {userIsAdmin(auth.user) && (
                  <List.Item>
                    <Row justify="space-between" style={{ width: '100%' }}>
                      <Col span={5} md={6} className="font-bold pr-2">
                        CPU Serial
                        <span className={detailPrefixStyle}>:</span>
                      </Col>
                      <Col span={19} md={18} className="pl-7">
                        {wosh.device_id}
                        <div className={editButtonLayoutStyle}>
                          <Button
                            type="primary"
                            shape="round"
                            ghost
                            onClick={() => {
                              woshUpdateCPUSerialModalCtrl.show();
                            }}
                            disabled={!userIsAdmin(auth.user)}
                          >
                            EDIT
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                )}
                <List.Item>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={5} md={6} className="font-bold pr-2">
                      ステータス
                      <span className={detailPrefixStyle}>:</span>
                    </Col>
                    <Col span={19} md={18} className="pl-7">
                      <WoshStatusTag wosh={wosh} />
                    </Col>
                  </Row>
                </List.Item>
                <List.Item>
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={5} md={6} className="font-bold pr-2">
                      コメント
                      <span className={detailPrefixStyle}>:</span>
                    </Col>
                    <Col span={19} md={18} className="pl-7">
                      {wosh.memo_client_to_client}
                      <div className={editButtonLayoutStyle}>
                        <Button
                          type="primary"
                          shape="round"
                          ghost
                          onClick={() =>
                            showCommentModal(
                              'コメント編集',
                              'memo_client_to_client',
                              wosh.memo_client_to_client
                            )
                          }
                          disabled={!wosh.abilities?.can_wosh_update}
                        >
                          EDIT
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </List.Item>
                {userIsAdmin(auth.user) && (
                  <List.Item>
                    <Row justify="space-between" style={{ width: '100%' }}>
                      <Col span={5} md={6} className="font-bold pr-2">
                        顧客向けコメント
                        <span className={detailPrefixStyle}>:</span>
                      </Col>
                      <Col span={19} md={18} className="pl-7">
                        {wosh.memo_operator_to_client}
                        <div className={editButtonLayoutStyle}>
                          <Button
                            type="primary"
                            shape="round"
                            ghost
                            onClick={() =>
                              showCommentModal(
                                '顧客向けコメント編集',
                                'memo_operator_to_client',
                                wosh.memo_operator_to_client
                              )
                            }
                          >
                            EDIT
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                )}
                {userIsAdmin(auth.user) && (
                  <List.Item>
                    <Row justify="space-between" style={{ width: '100%' }}>
                      <Col span={5} md={6} className="font-bold pr-2">
                        運営向けコメント
                        <span className={detailPrefixStyle}>:</span>
                      </Col>
                      <Col span={19} md={18} className="pl-7">
                        {wosh.memo_operator_to_operator}
                        <div className={editButtonLayoutStyle}>
                          <Button
                            type="primary"
                            shape="round"
                            ghost
                            onClick={() =>
                              showCommentModal(
                                '運営向けコメント編集',
                                'memo_operator_to_operator',
                                wosh.memo_operator_to_operator
                              )
                            }
                          >
                            EDIT
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              </List>
            </Col>
          </Row>
        </div>
      </ContentBlock>
      <WoshUpdatePlaceModal
        wosh={wosh}
        woshDetailApi={woshDetailApi}
        modalCtrl={woshUpdatePlaceModalCtrl}
      />
      <WoshUpdateCommentModal
        wosh={wosh}
        woshDetailApi={woshDetailApi}
        title={title}
        field={commentField}
        value={commentValue}
        modalCtrl={woshUpdateCommentModalCtrl}
      />
      <WoshUpdateGroupModal
        wosh={wosh}
        woshDetailApi={woshDetailApi}
        modalCtrl={woshUpdateGroupModalCtrl}
      />
      <WoshUpdateCPUSerialModal
        wosh={wosh}
        modalCtrl={woshUpdateCPUSerialModalCtrl}
      />
    </>
  );
};

export default WoshDetailMain;
