import React from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { ModalCtrl } from '@/hooks/useModalCtrl';
import { Wosh } from '@/utils/wosh';
import useWoshUpdatePlaceMutation, {
  WoshUpdatePlaceReq,
} from '@/hooks/useWoshUpdatePlaceMutation';
import { formItemValidateMessage, formItemValidateStatus } from '@/utils/form';

type WoshUpdatePlaceModalProps = {
  wosh: Wosh;
  woshDetailApi: any;
  modalCtrl: ModalCtrl;
};

const WoshUpdatePlaceModal: React.FC<WoshUpdatePlaceModalProps> = ({
  wosh,
  woshDetailApi,
  modalCtrl,
}) => {
  const woshUpdatePlaceMutation = useWoshUpdatePlaceMutation();
  const handleWoshUpdatePlace = (values: any) => {
    const req: WoshUpdatePlaceReq = {
      id: wosh.id,
      data: {
        place: values.place,
      },
    };
    woshUpdatePlaceMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        modalCtrl.hide();
        woshDetailApi.refetch();
      },
      onError: () => {
        notification.error({
          message: '失敗',
        });
      },
    });
  };
  return (
    <Modal
      title="設置場所の変更"
      footer={null}
      visible={modalCtrl.visible}
      onCancel={modalCtrl.hide}
    >
      <Form onFinish={handleWoshUpdatePlace}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="設置場所"
            name="place"
            initialValue={wosh.place}
            validateStatus={formItemValidateStatus(
              woshUpdatePlaceMutation.error,
              'place'
            )}
            help={formItemValidateMessage(
              woshUpdatePlaceMutation.error,
              'place'
            )}
          >
            <Input size="large" placeholder="設置場所を入力してください" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={modalCtrl.hide}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default WoshUpdatePlaceModal;
