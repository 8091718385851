import React from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { ModalCtrl } from '@/hooks/useModalCtrl';
import { Wosh } from '@/utils/wosh';
import useWoshUpdateCommentMutation, {
  WoshUpdateCommentReq,
} from '@/hooks/useWoshUpdateCommentMutation';
import { formItemValidateMessage, formItemValidateStatus } from '@/utils/form';

type WoshUpdateCommentModalProps = {
  wosh: Wosh;
  woshDetailApi: any;
  title: string;
  field: string;
  value: string;
  modalCtrl: ModalCtrl;
};

const WoshUpdateCommentModal: React.FC<WoshUpdateCommentModalProps> = ({
  wosh,
  woshDetailApi,
  title,
  field,
  value,
  modalCtrl,
}) => {
  const woshUpdateCommentMutation = useWoshUpdateCommentMutation();
  const handleWoshUpdateComment = (values: any) => {
    const req: WoshUpdateCommentReq = {
      id: wosh.id,
      data: {
        memo_client_to_client: values.memo_client_to_client,
        memo_operator_to_client: values.memo_operator_to_client,
        memo_operator_to_operator: values.memo_operator_to_operator,
      },
    };
    woshUpdateCommentMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        modalCtrl.hide();
        woshDetailApi.refetch();
      },
      onError: () => {
        notification.error({
          message: '失敗',
        });
      },
    });
  };
  const { TextArea } = Input;
  return (
    <Modal
      title={title}
      footer={null}
      visible={modalCtrl.visible}
      onCancel={modalCtrl.hide}
    >
      <Form onFinish={handleWoshUpdateComment}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            name={`${field}`}
            initialValue={value}
            validateStatus={formItemValidateStatus(
              woshUpdateCommentMutation.error,
              `${field}`
            )}
            help={formItemValidateMessage(
              woshUpdateCommentMutation.error,
              `${field}`
            )}
          >
            <TextArea rows={5} placeholder="コメントを入力してください" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={modalCtrl.hide}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default WoshUpdateCommentModal;
