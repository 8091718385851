// 廃止予定もう使わないで下さい。
import { useState } from 'react';

export type ModalCtrl = {
  hide: () => void;
  visible: boolean;
  show: (id?: number) => void;
  // table内でmodalを使用する場合、idでuniqueを判断する
  id: any;
};

const useModalCtrl = () => {
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState<number>(0);

  const show = (tmpId = 0) => {
    if (tmpId) {
      setId(tmpId);
    }
    setVisible(true);
  };

  const hide = () => {
    setId(0);
    setVisible(false);
  };

  return {
    id,
    visible,
    show,
    hide,
  };
};

export default useModalCtrl;
