import React from 'react';
import { Button, Form, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useQueryClient, UseQueryResult } from 'react-query';
import { ModalCtrl } from '@/hooks/useModalCtrl';
import { Wosh } from '@/utils/wosh';
import { formError } from '@/utils/form';
import useWoshUpdateCPUSerialMutation, {
  WoshUpdateCPUSerialMutationVariables,
} from '@/hooks/useWoshUpdateCPUSerialMutation';
import useWoshListQuery, { WoshListRes } from '@/hooks/useWoshListQuery';

type WoshUpdateCPUSerialModalProps = {
  wosh: Wosh;
  modalCtrl: ModalCtrl;
};

type FormInputValues = {
  deviceId: string;
};

const WoshUpdateCPUSerialModal: React.FC<WoshUpdateCPUSerialModalProps> = ({
  wosh,
  modalCtrl,
}) => {
  const queryClient = useQueryClient();
  const [form] = useForm<FormInputValues>();
  const formInitialValues: FormInputValues = { deviceId: wosh.device_id };
  const woshUpdateCPUSerialMutation = useWoshUpdateCPUSerialMutation();
  const { data }: UseQueryResult<WoshListRes, unknown> = useWoshListQuery({
    dangling_serial_number: true,
    ordering: '-created_at',
    page: 1,
    page_size: 100,
  });
  const woshesWithRegisterableCPUSerial = data ? data.results : [];

  const handleWoshUpdateCPUSerial = (formValues: FormInputValues) => {
    const woshWithDanglingSerialNumber = woshesWithRegisterableCPUSerial.find(
      (e) => e.device_id === formValues.deviceId
    );

    if (!woshWithDanglingSerialNumber) return;

    const mutationVariables: WoshUpdateCPUSerialMutationVariables = {
      woshUpdateCPUSerialPathParam: {
        id: wosh.id,
      },
      woshUpdateCPUSerialReqBody: {
        device_id: woshWithDanglingSerialNumber.device_id,
      },
    };
    woshUpdateCPUSerialMutation.mutate(mutationVariables, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        modalCtrl.hide();
        queryClient.invalidateQueries('woshDetail');
        queryClient.invalidateQueries('woshList');
      },
      onError: formError,
    });
  };

  const handleCancel = () => {
    form.resetFields();
    modalCtrl.hide();
  };

  return (
    <Modal
      title="CPU Serialの変更"
      footer={null}
      visible={modalCtrl.visible}
      onCancel={handleCancel}
    >
      <Form
        onFinish={handleWoshUpdateCPUSerial}
        form={form}
        initialValues={formInitialValues}
      >
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item label="CPU Serial" name="deviceId">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {woshesWithRegisterableCPUSerial.map((element) => {
                return (
                  <Select.Option key={element.id} value={element.device_id}>
                    {element.device_id}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={modalCtrl.hide}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default WoshUpdateCPUSerialModal;
