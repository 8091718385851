import React from 'react';
import { Tag } from 'antd';
import { User, userIsAdmin, userIsCompanyManager } from '@/utils/user';
import { GroupUser } from '@/utils/group_user';

type UserGroupUsersProps = {
  user: User;
};
const UserGroupUsers: React.FC<UserGroupUsersProps> = ({ user }) => {
  if (userIsAdmin(user)) {
    return <Tag>全て</Tag>;
  }
  if (userIsCompanyManager(user)) {
    return <Tag>全て(組織内)</Tag>;
  }
  return (
    <span>
      {user.group_users?.map((group_user: GroupUser) => (
        <Tag key={`tag${group_user.group.id}`}>{group_user.group?.name}</Tag>
      ))}
    </span>
  );
};

export default UserGroupUsers;
