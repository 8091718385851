import { Button, Form, Modal, Select, notification, Input } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useAdminUpdateMutation, {
  AdminUpdateReq,
} from '@/hooks/useAdminUpdateMutation';
import { User } from '@/utils/user';
import {
  formError,
  formItemValidateMessage,
  formItemValidateStatus,
} from '@/utils/form';

type AdminEditModalProps = {
  user: User;
  visible: boolean;
  onFinish: () => void;
  onCancel: () => void;
};

const AdminEditModal: React.FC<AdminEditModalProps> = ({
  visible,
  user,
  onFinish,
  onCancel,
}) => {
  const [adminUpdateForm] = useForm();
  const adminUpdateMutation = useAdminUpdateMutation();
  const handleAdminUpdate = (values: any) => {
    const req: AdminUpdateReq = {
      id: user.id,
      admin_role: values.admin_role,
      email: values?.email,
      name: values?.name,
      phone_number: values?.phone_number || '',
    };
    adminUpdateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        onFinish();
        adminUpdateForm.resetFields();
      },
      onError: formError,
    });
  };

  return (
    <Modal
      footer={null}
      onCancel={onCancel}
      title="Admin編集"
      visible={visible}
    >
      <Form onFinish={handleAdminUpdate} form={adminUpdateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="名前"
            name="name"
            initialValue={user?.name}
            validateStatus={formItemValidateStatus(
              adminUpdateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(adminUpdateMutation.error, 'name')}
          >
            <Input size="large" placeholder="名前を入力してください" />
          </Form.Item>
          <Form.Item
            label="Eメール"
            name="email"
            initialValue={user?.email}
            validateStatus={formItemValidateStatus(
              adminUpdateMutation.error,
              'email'
            )}
            help={formItemValidateMessage(adminUpdateMutation.error, 'email')}
          >
            <Input size="large" placeholder="Eメールを入力してください" />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name="phone_number"
            initialValue={user?.phone_number}
            validateStatus={formItemValidateStatus(
              adminUpdateMutation.error,
              'phone_number'
            )}
            help={formItemValidateMessage(
              adminUpdateMutation.error,
              'phone_number'
            )}
          >
            <Input size="large" placeholder="電話番号を入力してください" />
          </Form.Item>
          <Form.Item
            label="権限"
            name="admin_role"
            initialValue={user.admin_role}
            validateStatus={formItemValidateStatus(
              adminUpdateMutation.error,
              'admin_role'
            )}
            help={formItemValidateMessage(
              adminUpdateMutation.error,
              'admin_role'
            )}
          >
            <Select showSearch optionFilterProp="children">
              <Select.Option value={1}>admin</Select.Option>
              <Select.Option value={2}>オペレーター</Select.Option>
              <Select.Option value={3}>コールセンター</Select.Option>
              <Select.Option value={4}>工場</Select.Option>
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AdminEditModal;
