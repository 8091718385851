import { Button, Form, Input, Modal, notification, Select } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import useAdminCreateMutation, {
  AdminCreateReq,
} from '@/hooks/useAdminCreateMutation';
import useAuth from '@/hooks/useAuth';
import {
  formError,
  formItemValidateMessage,
  formItemValidateStatus,
} from '@/utils/form';

type AdminAddModalProps = {
  visible: boolean;
  onFinish: () => void;
  onCancel: () => void;
};

const AdminAddModal: React.FC<AdminAddModalProps> = ({
  visible,
  onFinish,
  onCancel,
}) => {
  const auth = useAuth();

  const [adminCreateForm] = useForm();
  const adminCreateMutation = useAdminCreateMutation();
  const handleAdminCreate = (values: any) => {
    const req: AdminCreateReq = {
      company: auth.user?.admin_role
        ? values.company_id
        : auth.user?.company?.id,
      email: values.email,
      name: values.name,
      password: values.password,
      phone_number: values.phone_number || '',
      admin_role: values.admin_role,
    };
    adminCreateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '作成しました',
        });
        onFinish();
        adminCreateForm.resetFields();
      },
      onError: formError,
    });
  };

  return (
    <Modal
      footer={null}
      title="Admin追加"
      visible={visible}
      onCancel={onCancel}
    >
      <Form onFinish={handleAdminCreate} form={adminCreateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="名前"
            name="name"
            validateStatus={formItemValidateStatus(
              adminCreateMutation.error,
              'name'
            )}
            help={formItemValidateMessage(adminCreateMutation.error, 'name')}
          >
            <Input size="large" placeholder="名前を入力してください" />
          </Form.Item>
          <Form.Item
            label="Eメール"
            name="email"
            validateStatus={formItemValidateStatus(
              adminCreateMutation.error,
              'email'
            )}
            help={formItemValidateMessage(adminCreateMutation.error, 'email')}
          >
            <Input size="large" placeholder="Eメールを入力してください" />
          </Form.Item>
          <Form.Item
            label="パスワード"
            name="password"
            validateStatus={formItemValidateStatus(
              adminCreateMutation.error,
              'password'
            )}
            help={formItemValidateMessage(
              adminCreateMutation.error,
              'password'
            )}
          >
            <Input size="large" placeholder="パスワードを入力してください" />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name="phone_number"
            validateStatus={formItemValidateStatus(
              adminCreateMutation.error,
              'phone_number'
            )}
            help={formItemValidateMessage(
              adminCreateMutation.error,
              'phone_number'
            )}
          >
            <Input size="large" placeholder="電話番号を入力してください" />
          </Form.Item>
          <Form.Item
            label="権限"
            name="admin_role"
            validateStatus={formItemValidateStatus(
              adminCreateMutation.error,
              'admin_role'
            )}
            help={formItemValidateMessage(
              adminCreateMutation.error,
              'admin_role'
            )}
          >
            <Select showSearch optionFilterProp="children">
              <Select.Option value={1}>admin</Select.Option>
              <Select.Option value={2}>オペレーター</Select.Option>
              <Select.Option value={3}>コールセンター</Select.Option>
              <Select.Option value={4}>工場</Select.Option>
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              追加する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AdminAddModal;
