import React, { useState } from 'react';
import { Menu, Dropdown, Button, Tag, Switch, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { KeyIcon, LogoutIcon } from '@/components/common/Icon';
import useAuth from '@/hooks/useAuth';
import { userIsAdmin, userIsCompany } from '@/utils/user';
import UserPasswordModal from '@/components/user/UserPasswordModal';
import useUserSendErrorMailUpdateMutation, {
  UserSendErrorMailUpdateReq,
} from '@/hooks/useUserSendErrorMailUpdateMutation';
import { formError } from '@/utils/form';

const accountMenuStyle = css`
  width: 180px;
  border-radius: 4px;
  margin: 0;
  background-color: #fff;
`;

const accountMenuItemStyle = css`
  margin: 0;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #707070;
`;

const headerAccountMenuStyle = css`
  display: inline-flex;
  align-items: center;
`;

const headerCompanyNameStyle = css`
  color: #2cbbdb;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-right: 0.5rem;
`;

const HeaderAccountMenu: React.FC = () => {
  const auth = useAuth();
  const [isShow, setIsShow] = useState(false);

  // passwordEditModal
  const [passwordEditModal, setPasswordEditModal] = useState(false);

  // エラーメール通知更新
  const userSendErrorMailUpdateMutation = useUserSendErrorMailUpdateMutation();
  const onChangeSendErrorMail = (checked: boolean) => {
    const req: UserSendErrorMailUpdateReq = {
      id: auth.user?.id,
      send_error_mail: checked,
    };
    userSendErrorMailUpdateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        auth.login();
      },
      onError: formError,
    });
  };

  if (!auth.user) {
    return null;
  }

  const accountMenu = (
    <Menu className={accountMenuStyle}>
      {!userIsAdmin(auth.user) && (
        <Menu.Item key="0" className={accountMenuItemStyle}>
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={onChangeSendErrorMail}
            checked={!!auth.user.send_error_mail}
            size="small"
          />
          エラーメール通知
        </Menu.Item>
      )}
      <Menu.Item
        key="1"
        icon={<KeyIcon style={{ marginLeft: '10px' }} />}
        className={accountMenuItemStyle}
        onClick={() => {
          setPasswordEditModal(true);
        }}
      >
        パスワード変更
      </Menu.Item>
      <UserPasswordModal
        visible={passwordEditModal}
        onFinish={async () => {
          setPasswordEditModal(false);
        }}
        onCancel={() => setPasswordEditModal(false)}
      />
      <Menu.Divider />
      <Menu.Divider />
      <Menu.Item
        key="2"
        icon={<LogoutIcon style={{ marginLeft: '10px' }} />}
        className={accountMenuItemStyle}
        onClick={auth.logout}
      >
        ログアウト
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={headerAccountMenuStyle}>
      <span className={headerCompanyNameStyle}>
        {userIsAdmin(auth.user) && <Tag>Admin</Tag>}
        {userIsCompany(auth.user) && (
          <>
            <Tag>組織</Tag>
            {auth.user?.company?.name}
          </>
        )}
      </span>
      <Dropdown overlay={accountMenu} visible={isShow}>
        <Button
          type="text"
          className="ant-dropdown-link"
          onClick={() => setIsShow((prevIsShow) => !prevIsShow)}
        >
          {auth.user.name}
          <DownOutlined
            style={{ fontSize: '10px' }}
            rotate={isShow ? 180 : 0}
          />
        </Button>
      </Dropdown>
    </div>
  );
};

export default HeaderAccountMenu;
