import { Button, Form, Input, Modal, notification, Select } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import useWoshCreateMutation, {
  WoshCreateReq,
} from '@/hooks/useWoshCreateMutation';
import { Company } from '@/utils/company';
import {
  formItemValidateStatus,
  formItemValidateMessage,
  formError,
} from '@/utils/form';
import { Group } from '@/utils/group';

type GroupAddModalProps = {
  visible: boolean;
  companies: Company[];
  groups: Group[];
  onFinish: () => void;
  onCancel: () => void;
};

const GroupAddModal: React.FC<GroupAddModalProps> = ({
  visible,
  companies,
  groups,
  onFinish,
  onCancel,
}) => {
  const [woshCreateForm] = useForm();
  const woshCreateMutation = useWoshCreateMutation();
  const handleWoshCreate = (values: any) => {
    const req: WoshCreateReq = {
      group: values.group_id,
      serial_number: values.serial_number || null,
      device_id: values.device_id || null,
      place: values.place,
    };
    woshCreateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '作成しました',
        });
        onFinish();
        woshCreateForm.resetFields();
      },
      onError: formError,
    });
  };

  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const changeCompanyId = (value: any) => {
    const tmpGroups =
      groups?.filter((group: Group) => {
        return value === group?.company_id;
      }) || [];
    setFilteredGroups(tmpGroups);
    woshCreateForm.setFieldsValue({
      group_id: null,
    });
  };

  return (
    <Modal footer={null} title="製品追加" visible={visible} onCancel={onCancel}>
      <Form onFinish={handleWoshCreate} form={woshCreateForm}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="組織"
            name="company_id"
            validateStatus={formItemValidateStatus(
              woshCreateMutation.error,
              'company_id'
            )}
            help={formItemValidateMessage(
              woshCreateMutation.error,
              'company_id'
            )}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onChange={changeCompanyId}
            >
              {companies?.map((tmp_company) => {
                return (
                  <Select.Option key={tmp_company.id} value={tmp_company.id}>
                    {tmp_company.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="グループ"
            name="group_id"
            initialValue={null}
            validateStatus={formItemValidateStatus(
              woshCreateMutation.error,
              'group'
            )}
            help={formItemValidateMessage(woshCreateMutation.error, 'group')}
          >
            <Select showSearch optionFilterProp="children">
              {filteredGroups?.map((filteredGroup) => {
                return (
                  <Select.Option
                    key={filteredGroup.id}
                    value={filteredGroup.id}
                  >
                    {filteredGroup.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="製造番号"
            name="serial_number"
            initialValue={null}
            validateStatus={formItemValidateStatus(
              woshCreateMutation.error,
              'serial_number'
            )}
            help={formItemValidateMessage(
              woshCreateMutation.error,
              'serial_number'
            )}
          >
            <Input size="large" placeholder="製造番号を入力してください" />
          </Form.Item>
          <Form.Item
            label="デバイスID"
            name="device_id"
            initialValue={null}
            validateStatus={formItemValidateStatus(
              woshCreateMutation.error,
              'device_id'
            )}
            help={formItemValidateMessage(
              woshCreateMutation.error,
              'device_id'
            )}
          >
            <Input size="large" placeholder="デバイスIDを入力してください" />
          </Form.Item>
          <Form.Item
            label="設置場所"
            name="place"
            initialValue=""
            validateStatus={formItemValidateStatus(
              woshCreateMutation.error,
              'place'
            )}
            help={formItemValidateMessage(woshCreateMutation.error, 'place')}
          >
            <Input size="large" placeholder="設置場所を入力してください" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              追加する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default GroupAddModal;
