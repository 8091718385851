import { Button, Form, Modal, notification, Input } from 'antd';
import React, { useState } from 'react';
import useUserPasswordUpdateMutation, {
  UserPasswordUpdateReq,
} from '@/hooks/useUserPasswordUpdateMutation';
import { formItemValidateMessage, formItemValidateStatus } from '@/utils/form';
import useAuth from '@/hooks/useAuth';

type UserPasswordModalProps = {
  visible: boolean;
  onFinish: () => void;
  onCancel: () => void;
};

const UserPasswordModal: React.FC<UserPasswordModalProps> = ({
  visible,
  onFinish,
  onCancel,
}) => {
  const auth = useAuth();
  const [helpMessage, setHelpMessage] = useState<string>('');

  const userPasswordUpdateMutation = useUserPasswordUpdateMutation();
  // eslint-disable-next-line consistent-return
  const handleUserPasswordUpdate = (values: any) => {
    if (!auth.user) {
      return false;
    }
    setHelpMessage('');

    if (values.new_password !== values.check_password) {
      setHelpMessage('パスワードが違います');
      return false;
    }

    const req: UserPasswordUpdateReq = {
      id: auth.user?.id,
      new_password: values?.new_password,
    };
    userPasswordUpdateMutation.mutate(req, {
      onSuccess: () => {
        notification.success({
          message: '更新しました',
        });
        onFinish();
      },
      onError: () => {
        notification.error({
          message: '失敗',
        });
        onFinish();
      },
    });
  };

  return (
    <Modal
      footer={null}
      onCancel={onCancel}
      visible={visible}
      title="ユーザー更新"
    >
      <Form onFinish={handleUserPasswordUpdate}>
        <div style={{ maxWidth: '420px', margin: 'auto' }}>
          <Form.Item
            label="新しいパスワード"
            name="new_password"
            validateStatus={formItemValidateStatus(
              userPasswordUpdateMutation.error,
              'new_password'
            )}
            help={formItemValidateMessage(
              userPasswordUpdateMutation.error,
              'new_password'
            )}
            rules={[{ required: true }]}
          >
            <Input.Password size="large" placeholder="パスワード" />
          </Form.Item>
          <Form.Item
            label="新しいパスワードの確認"
            name="check_password"
            validateStatus={helpMessage ? 'error' : ''}
            help={helpMessage}
            rules={[{ required: true }]}
          >
            <Input.Password size="large" placeholder="パスワード" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button shape="round" size="large" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-5"
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ minWidth: '180px', fontWeight: 'bold' }}
            >
              更新する
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UserPasswordModal;
